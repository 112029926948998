import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Stack,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import {
  Typography14,
  Typography20,
} from "../../components/Typography/Typography";
import { useState, useEffect } from "react";
import axios from "axios";
import { LoadingButton } from "@mui/lab";
import { findObjectLength } from "../../utils/reusableFunction/resusableFn";
import ApiService from "../../APIs/api";

const AddProducts = (props: any) => {
  const value = props.value;
  console.log(value, props.value);
  const [page, setPage] = useState(1);
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [btnDisabled, setbtnDisabled] = useState(true);
  const [IndustryList, setIndustryList] = useState<any>([]);
  const [selectIndustrySearchObj, setselectIndustrySearchObj] = useState<any>(
    {}
  );
  const [CategoryList, setCategoryList] = useState<any>([]);
  const [selectCategorySearchObj, setselectCategorySearchObj] = useState<any>(
    {}
  );
  const [productName, setproductName] = useState("");
  const [productLists, setProductLists] = useState<any>([]);
  const token = props.token;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    console.log(newPage, "newPage");
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  const handleOnChangesetProducts = (e: any) => {
    setproductName(e.target.value);
    if (e.target.value !== "") {
      console.log("cop[[[[");
      setbtnDisabled(false);
    } else {
      console.log("cop[[[[");
      setbtnDisabled(true);
    }
  };
  const handleOnChangeAddProducts = async (e: any) => {
    e.preventDefault();
    // setverifyLoading(true);
    let data = new FormData(e.currentTarget);
    const axiosConfig = {
      headers: {
        token: token ? token : "",
      },
    };
    let body = {
      productName: data.get("productName"),
      categoryId: selectCategorySearchObj._id
        ? selectCategorySearchObj._id
        : "",
    };

    const setToken = ApiService.setToken(token);
    const responseData: any = ApiService.post("/admin/addProduct", body);
    if (responseData.status === 200) {
      // setverifyLoading(false);
      setbtnDisabled(true);
      MountedgetProductlists();
      // setcategoryAddSuccessMsg(true);
    } else {
      console.log(responseData);
      // setverifyLoading(false);
      // setcategoryErrorMsg(true);
    }
  };
  const getIndustryListBySearch = async () => {
    let params = {
      rowsLimitInPage: rowsPerPage,
      page: page,
      searchKey: "",
    };
    const setToken = ApiService.setToken(token);
    const responseData: any = ApiService.get("/admin/getIndustry", params);
    if (responseData.status === 200) {
      setIndustryList(responseData.data.result);
    } else {
      console.log(responseData);
    }
  };
  const MountedgetProductlists = async () => {
    let params = {
      rowsLimitInPage: rowsPerPage,
      page: page,
      categoryId: selectCategorySearchObj._id,
    };
    const setToken = ApiService.setToken(token);
    const responseData: any = ApiService.get("/admin/getProduct", params);
    if (responseData.status === 200) {
      setProductLists(responseData.data.result);
    } else {
      console.log(responseData);
    }
  };
  useEffect(() => {
    if (findObjectLength(selectCategorySearchObj)) {
      MountedgetProductlists();
    }
  }, [selectCategorySearchObj]);
  const getCategoryListBySearch = async () => {
    let params = {
      rowsLimitInPage: rowsPerPage,
      page: page,
      searchKey: "",
    };
    const setToken = await ApiService.setToken(token);
    const responseData: any = await ApiService.get(
      "/admin/getCategory",
      params
    );
    if (responseData.status === 200) {
      setCategoryList(responseData.data.result);
    } else {
      console.log(responseData);
    }
  };
  return (
    <>
      {" "}
      <Box component={"form"} onSubmit={handleOnChangeAddProducts}>
        <Stack px={2} pt={3}>
          <Typography20 color={"#334A67"} text={"Add Products"}></Typography20>
        </Stack>
        <Stack px={2} spacing={3} mt={2} direction={"row"} display={"flex"}>
          <Stack spacing={1}>
            <Typography14
              color={"#556486"}
              text={"Select Industry"}
            ></Typography14>
            <Autocomplete
              size="small"
              options={IndustryList}
              disableClearable
              getOptionLabel={(option: any) => option.industryName}
              onChange={(event, value) =>
                setselectIndustrySearchObj(
                  value !== (undefined || null) ? value : {}
                )
              }
              fullWidth
              sx={{
                minWidth: "20vw",
              }}
              renderInput={(params: any) => (
                <TextField
                  fullWidth
                  sx={{
                    "& .MuiFormHelperText-root": {
                      color: "Red",
                      fontFamily: "Public Sans",
                      fontWeight: "600",
                      fontSize: "8",
                    },
                  }}
                  {...params}
                  onFocus={getIndustryListBySearch}
                  onChange={getIndustryListBySearch}
                  InputLabelProps={{
                    sx: {
                      fontSize: "16px",
                      fontFamily: "Public Sans",
                      color: "#919EAB",
                      height: "40px",
                      fontWeight: 400,
                    },
                  }}
                  label="Search Industry"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    style: {
                      borderRadius: "8px",
                    },
                  }}
                />
              )}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography14
              color={"#556486"}
              text={"Select Category"}
            ></Typography14>
            <Autocomplete
              size="small"
              disableClearable
              options={CategoryList}
              getOptionLabel={(option: any) => option.categoryName}
              onChange={(event, value) =>
                setselectCategorySearchObj(
                  value !== (undefined || null) ? value : {}
                )
              }
              fullWidth
              sx={{
                minWidth: "20vw",
              }}
              renderInput={(params: any) => (
                <TextField
                  fullWidth
                  sx={{
                    "& .MuiFormHelperText-root": {
                      color: "Red",
                      fontFamily: "Public Sans",
                      fontWeight: "600",
                      fontSize: "8",
                    },
                  }}
                  {...params}
                  onFocus={getCategoryListBySearch}
                  onChange={getCategoryListBySearch}
                  InputLabelProps={{
                    sx: {
                      fontSize: "16px",
                      fontFamily: "Public Sans",
                      color: "#919EAB",
                      height: "40px",
                      fontWeight: 400,
                    },
                  }}
                  label="Search Category"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    style: {
                      borderRadius: "8px",
                    },
                  }}
                />
              )}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography14
              color={"#556486"}
              text={"Add Products"}
            ></Typography14>
            <TextField
              size="small"
              onChange={handleOnChangesetProducts}
              sx={{
                "& .MuiFormLabel-root": {
                  color: "#919EAB",
                  fontFamily: "Public Sans",
                  fontWeight: "400",
                  fontStyle: "normal",
                  fontSize: 14,
                  lineHeight: "14px",
                },
                "& .MuiFormHelperText-root": {
                  fontFamily: "Public Sans",
                  fontWeight: 400,
                },
              }}
              InputProps={{
                style: {
                  borderRadius: "8px",
                  borderColor: "#919EAB",
                  minWidth: "20vw",
                },
              }}
              label={"Products"}
              name="productName"
              fullWidth
            />
          </Stack>
        </Stack>
        <Box sx={{ px: 2, py: 3 }}>
          <LoadingButton
            size="small"
            disabled={btnDisabled}
            sx={{
              borderRadius: "8px",
              fontSize: "14",
              fontFamily: "Public Sans",
              gap: "8px",
              backgroundColor: btnDisabled
                ? "rgba(145, 158, 171, 0.24)"
                : "#303981",
              textTransform: "none",
              color: btnDisabled ? "grey" : "white",
              "&:hover": {
                backgroundColor: btnDisabled
                  ? "rgba(145, 158, 171, 0.24)"
                  : "#303981",
              },
            }}
            // loading={verifyLoading}
            type="submit"
          >
            Add
          </LoadingButton>
        </Box>
        <Box
          boxShadow={1}
          mx={2}
          my={3}
          sx={{ width: "30vw", borderRadius: "8px", backgroundColor: "white" }}
        >
          <Box
            sx={{
              backgroundColor: "var(--background-light-neutral, #F4F6F8)",
              px: 4,
              py: 3,
            }}
          >
            <Typography14 color={"#556486"} text={"Products"}></Typography14>
          </Box>
          <Box>
            <Stack sx={{ px: 4, py: 2 }} spacing={3}>
              {productLists.length > 0 ? (
                productLists.map((item: any, index: any) => (
                  <Typography14
                    text={item ? item.productName : "N/A"}
                  ></Typography14>
                ))
              ) : (
                <Typography14
                  sx={{ textAlign: "center" }}
                  text={"No Products Found !"}
                  color={"dark"}
                ></Typography14>
              )}
            </Stack>
            <Divider></Divider>
            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"end"}
              px={0}
              mx={0}
            >
              <TablePagination
                component="div"
                count={50}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddProducts;
