import {
  Autocomplete,
  Box,
  Divider,
  Stack,
  TablePagination,
  TextField,
} from "@mui/material";
import {
  Typography14,
  Typography20,
} from "../../components/Typography/Typography";
import { useState, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import { findObjectLength } from "../../utils/reusableFunction/resusableFn";
import DoneIcon from "@mui/icons-material/Done";
import ApiService from "../../APIs/api";

const AddCategory = (props: any) => {
  const [page, setPage] = useState(1);
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [btnDisabled, setbtnDisabled] = useState(true);
  const [verifyLoading, setverifyLoading] = useState(false);
  const [category, setcategory] = useState("");
  const [IndustryList, setIndustryList] = useState<any>([]);
  const [selectIndustrySearchObj, setselectIndustrySearchObj] = useState<any>(
    {}
  );
  const [categoryList, setcategoryList] = useState<any>([]);
  const [categorDisabled, setcategorDisabled] = useState(true);
  const [categoryErrorMsg, setcategoryErrorMsg] = useState(false);
  const [categoryAddSuccessMsg, setcategoryAddSuccessMsg] = useState(false);

  const token = props.token;
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
  };
  console.log(selectIndustrySearchObj, "selectIndustrySearchObj");
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
        width: 140,
        borderRadius: "8px",
      },
    },
  };
  const handleonClickAddCategory = async (e: any) => {
    e.preventDefault();
    setverifyLoading(true);
    let data = new FormData(e.currentTarget);
    let body = {
      categoryName: data.get("categoryName"),
      industryId: selectIndustrySearchObj._id
        ? selectIndustrySearchObj._id
        : "",
    };
    const responseData: any = await ApiService.post("/admin/addCategory", body);
    if (responseData.status === 200) {
      setverifyLoading(false);
      setbtnDisabled(true);
      setcategory("");
      setcategoryAddSuccessMsg(true);
      OnMountedCategoryList();
    } else {
      console.log(responseData);
      setbtnDisabled(true);
      setverifyLoading(false);
      setcategoryErrorMsg(true);
    }
  };
  const getIndustryListBySearch = async () => {
    let params = {
      rowsLimitInPage: rowsPerPage,
      page: page,
      searchKey: "",
    };
    const responseData: any = await ApiService.get(
      "/admin/getIndustry",
      params
    );
    if (responseData.status === 200) {
      setIndustryList(responseData.data.result);
    } else {
      console.log(responseData);
    }
  };
  const handleOnChangeAddCategory = (e: any) => {
    setcategoryAddSuccessMsg(false);
    setcategory(e.target.value);
    if (e.target.value !== "") {
      setbtnDisabled(false);
    } else {
      setbtnDisabled(true);
    }
  };
  useEffect(() => {
    if (findObjectLength(selectIndustrySearchObj) > 0) {
      setcategorDisabled(false);
    } else {
      setcategorDisabled(true);
    }
  });
  const OnMountedCategoryList = async () => {
    let params = {
      rowsLimitInPage: rowsPerPage,
      page: page,
      industryId: selectIndustrySearchObj._id
        ? selectIndustrySearchObj._id
        : "",
    };
    const responseData: any = await ApiService.get(
      "/admin/getCategory",
      params
    );
    if (responseData.status === 200) {
      setcategoryList(responseData.data.result);
    } else {
      console.log(responseData);
    }
  };

  useEffect(() => {
    if (findObjectLength(selectIndustrySearchObj)) {
      OnMountedCategoryList();
    }
  }, [selectIndustrySearchObj]);

  return (
    <>
      {" "}
      <Box component={"form"} onSubmit={handleonClickAddCategory}>
        <Stack px={2} pt={3}>
          <Typography20 color={"#334A67"} text={"Add Category"}></Typography20>
        </Stack>
        <Stack px={2} spacing={2} mt={2} direction={"row"} display={"flex"}>
          <Stack spacing={1}>
            <Typography14
              color={"#556486"}
              text={"Select Industry"}
            ></Typography14>
            <Autocomplete
              size="small"
              disableClearable
              options={IndustryList}
              getOptionLabel={(option: any) => option.industryName}
              onChange={(event, value) =>
                setselectIndustrySearchObj(
                  value !== (undefined || null) ? value : {}
                )
              }
              fullWidth
              sx={{
                minWidth: "25vw",
              }}
              renderInput={(params: any) => (
                <TextField
                  fullWidth
                  sx={{
                    "& .MuiFormHelperText-root": {
                      color: "Red",
                      fontFamily: "Public Sans",
                      fontWeight: "600",
                      fontSize: "8",
                    },
                  }}
                  {...params}
                  onFocus={getIndustryListBySearch}
                  onChange={getIndustryListBySearch}
                  InputLabelProps={{
                    sx: {
                      fontSize: "16px",
                      fontFamily: "Public Sans",
                      color: "#919EAB",
                      height: "40px",
                      fontWeight: 400,
                    },
                  }}
                  label="Search Industry"
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                    style: {
                      borderRadius: "8px",
                    },
                  }}
                />
              )}
            />
          </Stack>
          <Stack spacing={1}>
            <Typography14
              color={"#556486"}
              text={"Select Category"}
            ></Typography14>
            <TextField
              disabled={categorDisabled}
              value={category}
              sx={{
                "& .MuiFormLabel-root": {
                  color: "#919EAB",
                  fontFamily: "Public Sans",
                  fontWeight: "400",
                  fontStyle: "normal",
                  fontSize: 12,
                  lineHeight: "12px",
                  pb: 2,
                },
                "& .MuiFormHelperText-root": {
                  fontFamily: "Public Sans",
                  fontWeight: 400,
                },
              }}
              onChange={handleOnChangeAddCategory}
              InputProps={{
                style: {
                  borderRadius: "8px",
                  height: "40px",
                  borderColor: "#919EAB",
                  minWidth: "25vw",
                },
              }}
              label={"Category"}
              name="categoryName"
            />
          </Stack>
        </Stack>

        <Box sx={{ px: 2, py: 3 }}>
          <Stack display={"flex"} direction={"row"} spacing={2}>
            <LoadingButton
              size="small"
              disabled={btnDisabled}
              sx={{
                borderRadius: "8px",
                fontSize: "14",
                fontFamily: "Public Sans",
                gap: "8px",
                backgroundColor: btnDisabled
                  ? "rgba(145, 158, 171, 0.24)"
                  : "#303981",
                textTransform: "none",
                color: btnDisabled ? "grey" : "white",
                "&:hover": {
                  backgroundColor: btnDisabled
                    ? "rgba(145, 158, 171, 0.24)"
                    : "#303981",
                },
              }}
              loading={verifyLoading}
              type="submit"
            >
              Add
            </LoadingButton>
            {categoryAddSuccessMsg ? (
              <Box>
                <Stack
                  display={"flex"}
                  direction={"row"}
                  spacing={0.5}
                  mt={0.6}
                >
                  <DoneIcon style={{ fontSize: "20px", color: "#36B37E" }} />{" "}
                  <Typography14
                    fontWeight={600}
                    color={"#36B37E"}
                    text={"Category added successfully !"}
                  >
                    {" "}
                  </Typography14>
                </Stack>
              </Box>
            ) : (
              ""
            )}
            {categoryErrorMsg ? (
              <Box>
                <Stack
                  display={"flex"}
                  direction={"row"}
                  spacing={0.5}
                  mt={0.6}
                >
                  <Typography14
                    fontWeight={600}
                    color={"red"}
                    text={"Something went wrong, please try again !"}
                  >
                    {" "}
                  </Typography14>
                </Stack>
              </Box>
            ) : (
              ""
            )}
          </Stack>
        </Box>

        <Box
          boxShadow={1}
          mx={2}
          my={3}
          sx={{ width: "30vw", borderRadius: "8px", backgroundColor: "white" }}
        >
          <Box
            sx={{
              backgroundColor: "var(--background-light-neutral, #F4F6F8)",
              px: 4,
              py: 3,
            }}
          >
            <Typography14 color={"#556486"} text={"Categories"}></Typography14>
          </Box>
          <Box>
            <Stack sx={{ px: 4, py: 2 }} spacing={3}>
              {categoryList.length > 0 ? (
                categoryList.map((item: any, index: any) => (
                  <Typography14
                    text={item ? item.categoryName : "N/A"}
                  ></Typography14>
                ))
              ) : (
                <Typography14
                  sx={{ textAlign: "center" }}
                  text={"No Category Found !"}
                  color={"dark"}
                ></Typography14>
              )}
            </Stack>
            <Divider></Divider>
            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"end"}
              px={0}
              mx={0}
            >
              <TablePagination
                component="div"
                count={50}
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Stack>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default AddCategory;
