import { CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

const CircularTableLoader = () => {
  return (
    <Box display={"flex"} justifyContent={"center"} textAlign={"center"} my={4}>
      <CircularProgress color="inherit" />
    </Box>
  );
};

export default CircularTableLoader;
