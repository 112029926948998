import UserKycTable from "./UserKycListTable";
import {
  Box,
  Divider,
  InputAdornment,
  Stack,
  TablePagination,
  TextField,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { Chips } from "../../components/userPage/chips/Chips";
import { TypographyText } from "../../components/userPage/typography/TypographyText";
import { useSelector } from "react-redux";
import { Typography20 } from "../../components/Typography/Typography";
import ApiService from "../../APIs/api";

const Main = () => {
  const token = useSelector((state: any) => state.user.userLogin.token);
  const [open, setOpen] = useState(false);
  // user Kyc Status for approver or new or rejected state
  const [userKycStatusValue, setuserKycStatusValue] = useState<any>("");

  // userlist for save data from all users
  const [UserList, setUserList] = useState([]);
  const [UserListCount, setUserListCount] = useState<any>({});
  const [searchValue, setsearchValue] = useState("");
  // onchange Event for change state for kyc status for user
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setuserKycStatusValue(newValue);
  };

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    console.log(newPage, "newPage");
    setPage(newPage);
    getUsersList();
  };
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(1);
    getUsersList();
  };
  const handleClickInActiveStatus = async (value: any) => {
    await ApiService.setToken(token);
    const responseData: any = await ApiService.put(
      "/admin/updatebusinessstatus",
      {
        user: value,
        status: "inActive",
      }
    );
    if (responseData.status === 200) {
      console.log("response Data", responseData);
      // enqueueSnackbar("Status Disabled Successfully", {
      //   variant: "error",
      //   style: {
      //     color: "white",
      //     // color: "rgba(54, 179, 126, 1)",
      //     backgroundColor: "#FF7276",
      //     marginRight: "100px",
      //     fontFamily: "Public Sans",
      //     fontWeight: 500,
      //     fontSize: "14px",
      //     lineHeight: "22px",
      //     maxWidth: "400px",
      //     marginTop: "50px",
      //   },
      // });
    } else {
      console.log(responseData, "err business network");
    }
  };
  const handleClickActiveStatus = async (value: any) => {
    await ApiService.setToken(token);
    const responseData: any = await ApiService.put(
      "/admin/updatebusinessstatus",
      { user: value, status: "active" }
    );
    if (responseData.status === 200) {
      console.log("res", responseData);
      getUsersList();
    } else {
      console.log(responseData, "err business network");
    }
  };

  //  Get data on mounted through api using UseEffect hooks
  const getUsersList = async () => {
    setOpen(true);
    let params = {
      page: page > 0 ? page : 1,
      pageSize: rowsPerPage,
    };
    if (userKycStatusValue !== "") {
      Object.assign(params, {
        due: userKycStatusValue,
        page: page,
        pageSize: rowsPerPage,
      });
    }
    if (searchValue !== "") {
      Object.assign(params, {
        search: searchValue,
      });
    }
    // userToken check
    if (token !== "") {
      const setToken = await ApiService.setToken(token);
      const responseData: any = await ApiService.get(
        "/admin/getallkyc",
        params
      );
      if (responseData.status === 200) {
        let count: any = responseData.data.result.dueCounts;
        let data: any = responseData.data.result.results;
        setUserListCount(count);
        setUserList(data);
        setOpen(false);
      } else {
        console.log(responseData);
        setOpen(false);
      }
    }
  };

  useEffect(() => {
    getUsersList();
  }, [userKycStatusValue, searchValue]);

  return (
    <Box justifyContent={"center"} component={"div"} py={2}>
      <Stack mx={3}>
        <Typography20
          sx={{ mt: 2 }}
          fontWeight={700}
          fontStyle={"normal"}
          color={"#000000"}
          text={"Business"}
        ></Typography20>
      </Stack>
      <Box
        mx={3}
        borderRadius={"16px"}
        border={"1px solid #EEF2FA"}
        my={1.5}
        boxShadow={
          "0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)"
        }
        sx={{
          borderStyle: "dashed",
        }}
      >
        <Tabs
          value={userKycStatusValue}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="secondary"
          sx={{
            px: 2,
            "& .MuiTabs-indicator": {
              width: "auto",
              // maxWidth: "80px",
              backgroundColor: "rgba(48, 57, 129, 1)",
            },
          }}
        >
          <Tab
            value=""
            sx={{ textTransform: "none", px: 2 }}
            label={
              <Stack display={"flex"} direction={"row"} spacing={1.5}>
                <Chips
                  count={UserListCount.total ? UserListCount.total : 0}
                  chipColor={"rgba(0, 184, 217, 0.16)"}
                  chipFontColor={"#006C9C"}
                />
                <TypographyText color={"black"} text={"All"} />
              </Stack>
            }
          ></Tab>
          <Tab
            value="Approved"
            sx={{ textTransform: "none", px: 4 }}
            label={
              <Stack display={"flex"} direction={"row"} spacing={1.5}>
                <Chips
                  count={UserListCount.approved ? UserListCount.approved : 0}
                  chipColor={"rgba(54, 179, 126, 0.16)"}
                  chipFontColor={"rgba(27, 128, 106, 1)"}
                />
                <TypographyText
                  color={"rgba(99, 115, 129, 1)"}
                  text={"Approved"}
                />
              </Stack>
            }
          />
          <Tab
            value="New"
            sx={{ textTransform: "none", px: 4 }}
            label={
              <Stack display={"flex"} direction={"row"} spacing={1.5}>
                <Chips
                  count={UserListCount.new ? UserListCount.new : 0}
                  chipColor={"rgba(255, 171, 0, 0.16)"}
                  chipFontColor={"rgba(183, 110, 0, 1)"}
                />
                <TypographyText color={"rgba(99, 115, 129, 1)"} text={"New"} />
              </Stack>
            }
          />
          <Tab
            value="Rejected"
            sx={{ textTransform: "none", px: 4 }}
            label={
              <Stack display={"flex"} direction={"row"} spacing={1.5}>
                <Chips
                  count={UserListCount.rejected ? UserListCount.rejected : 0}
                  chipColor={"rgba(255, 86, 48, 0.16)"}
                  chipFontColor={"rgba(183, 29, 24, 1)"}
                />
                <TypographyText
                  color={"rgba(99, 115, 129, 1)"}
                  text={"Rejected"}
                />
              </Stack>
            }
          />
        </Tabs>
        <Divider sx={{ color: "rgba(145, 158, 171, 0.24)" }}></Divider>
        <Stack sx={{ my: 1, mx: 3 }}>
          {" "}
          <TextField
            fullWidth
            onChange={(e) => setsearchValue(e.target.value)}
            sx={{ my: 1 }}
            placeholder="Search By GST & Reference Number....."
            InputProps={{
              style: { borderRadius: "10px" },
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </Stack>
        <Box>
          <UserKycTable
            props={UserList}
            handleClickActiveStatus={handleClickActiveStatus}
            handleClickInActiveStatus={handleClickInActiveStatus}
            open={open}
          />
        </Box>
        <Box>
          <Divider></Divider>
          <Stack display={"flex"} direction={"row"} justifyContent={"end"}>
            <TablePagination
              component="div"
              count={50}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default Main;
