import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import NoDataFoundIcon from "../../assets/NoRecordFound.svg";

const NoRecordFound = (props: any) => {
  const text1 = props ? props.text1 : "";
  const text2 = props ? props.text2 : "";
  return (
    <div>
      <Box
        boxShadow={1}
        // mx={4}
        // my={2}
        mb={2}
        borderRadius={"16px"}
        gap={"40px"}
        display={"flex"}
        justifyContent={"center"}
        sx={{ borderRadius: "8px", backgroundColor: "white" }}
        height={"auto"}
      >
        <Stack
          mt={8}
          display={"flex"}
          justifyContent={"center"}
          textAlign={"center"}
        >
          <img src={NoDataFoundIcon} alt="" height={"150px"} width={"auto"} />
          <Typography
            fontFamily={"Public Sans"}
            fontSize={24}
            sx={{ mt: 4 }}
            fontWeight={700}
            fontStyle={"normal"}
            color={"#161C24"}
          >
            No {text1} Records Found
          </Typography>
          <Typography
            fontFamily={"Public Sans"}
            fontSize={"14px"}
            fontStyle={"normal"}
            lineHeight={"18px"}
            color={"#454F5B"}
            fontWeight={400}
            sx={{ my: 6, mt: 1 }}
          >
            Please add new {text2} for see details
          </Typography>
        </Stack>
      </Box>
    </div>
  );
};

export default NoRecordFound;
