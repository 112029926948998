import React from "react";
import ActivityBox from "../../../components/userPage/business/box/ActivityBox";
import { Box, Step, StepLabel, Stepper } from "@mui/material";

const BusinessActivity = () => {
  const steps = [
    { bgColor: "rgba(255, 86, 48, 0.25)", status: "Rejected" },
    { bgColor: "rgba(54, 179, 126, 0.25)", status: "Approved" },
  ];

  return (
    <>
      <Box sx={{ mx: 4 }}>
        <Stepper orientation="vertical">
          {steps.map((step) => (
            <Step>
              <StepLabel
                sx={{
                  "& .MuiStepIcon-root.Mui-active": {
                    color: step.bgColor,
                  },
                  "& .MuiStepIcon-root": {
                    color: step.bgColor,
                  },
                }}
              >
                <Box>
                  <ActivityBox bgColor={step.bgColor} status={step.status} />
                </Box>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </>
  );
};

export default BusinessActivity;
