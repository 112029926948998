import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import * as React from "react";
import {
  Typography14,
  Typography20,
  Typography24,
} from "../../components/Typography/Typography";
import AddIndustry from "./AddIndustry";
import AddCategory from "./AddCategory";
import AddProducts from "./AddProducts";
import { useEffect, useState } from "react";

const BusinessNetworkTools = () => {
  const [token, setToken] = useState("");
  const [value, setValue] = useState("one");
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  let array = [
    { label: "Name" },
    { label: "Type" },
    { label: "Personal Email" },
    { label: "Phone Number" },
    { label: "PAN (Individual/Company)" },
    { label: "Aadhar Card" },
    { label: "Address" },
    { label: "Pincode" },
    { label: "Business Location" },
    { label: "Bank" },
    { label: "IFSC Code" },
    { label: "Bank Account Number" },
  ];
  useEffect(() => {
    const userToken: any = localStorage.getItem("Token");
    setToken(userToken);
  }, []);
  return (
    <div>
      <Box
        justifyContent={"center"}
        component={"div"}
        py={"14px"}
        sx={{
          backgroundColor: "Ghost White",
        }}
      >
        <Stack px={4}>
          <Typography24
            sx={{ mt: 1 }}
            color={"#000000"}
            text={"Business Network Tools"}
          ></Typography24>
        </Stack>
        <Box
          boxShadow={1}
          mx={4}
          my={2}
          px={4}
          py={2}
          sx={{ borderRadius: "8px", backgroundColor: "white" }}
        >
          <Box sx={{ width: "100%" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="inherit"
              sx={{
                // px: 8,
                "& .MuiTab-root.Mui-selected": {
                  color: "#303981",
                },
                "& .MuiTabs-indicator": {
                  ml: 2,
                  maxWidth: "61px",
                  backgroundColor: "#303981",
                },
              }}
            >
              <Tab
                value="one"
                label={
                  <Typography14
                    color={"#334A67"}
                    fontSize={"14px"}
                    text={"Industry"}
                    sx={{ textTransform: "none" }}
                  ></Typography14>
                }
                sx={{ textTransform: "none" }}
              />
              <Tab
                value="two"
                label={
                  <Typography14
                    color={"#334A67"}
                    fontSize={"14px"}
                    text={"Category"}
                    sx={{ textTransform: "none" }}
                  ></Typography14>
                }
              />
              <Tab
                value="three"
                label={
                  <Typography14
                    color={"#334A67"}
                    fontSize={"14px"}
                    text={"Product"}
                    sx={{ textTransform: "none" }}
                  ></Typography14>
                }
                sx={{ textTransform: "none" }}
              />
            </Tabs>
          </Box>
          {value === "one" ? <AddIndustry value={value} token={token} /> : ""}
          {value === "two" ? <AddCategory value={value} token={token} /> : ""}
          {value === "three" ? <AddProducts value={value} token={token} /> : ""}
        </Box>
      </Box>
    </div>
  );
};

export default BusinessNetworkTools;
