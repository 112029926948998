import {
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  Paper,
  Stack,
  Avatar,
  Dialog,
  CircularProgress,
  DialogActions,
  Container,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import ImageRatio from "../../../assets/ImageRatio.svg";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
// import AWS from "aws-sdk";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";
import { experimentalStyled as styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import RemainingIcon from "../../../assets/ic_info.svg";
// import CustomizedDialogs from "../../../components/dialog/Dialog";
import BusinessActivity from "./BusinessActivity";
import { BusinessRejectedDocs } from "../../../api/bussiness/businessApi";
import { Link } from "react-router-dom";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ApiService from "../../../APIs/api";

const BusinessReferenceNumber = () => {
  // token from redux
  const token = useSelector((state: any) => state.user.userLogin.token);
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
  const [Status0, setStatus0] = useState(false);
  const [Status1, setStatus1] = useState(false);
  const [Status2, setStatus2] = useState(false);
  const [Status3, setStatus3] = useState(false);
  const navigate = useNavigate();

  // business details data
  const [expandBusinessDetails, setexpandBusinessDetails] = useState(true);
  const [userBusinessDetails, setuserBusinessDetails] = useState<any>({});
  const [GstRemarksDialog, setGstRemarksDialog] = useState(false);
  const [businessDetailsFetchStatus, setBusinessDetailsFetchStatus] =
    useState(false);
  const [businessDetailsDocs, setbusinessDetailsDocs] = useState<any>({});
  const [BusinessDetailsDocsStatus, setBusinessDetailsDocsStatus] =
    useState<any>({});
  const [DocsDialog, setDocsDialog] = useState(false);
  // adhar representative details
  const [expandAadharDetails, setexpandAadharDetails] = useState(true);
  const [userAadharDetails, setuserAadharDetails] = useState<any>({});
  const [AadharDetailsDocs, setAadharDetailsDocs] = useState<any>({});
  const [AadharDetailsDocsStatus, setAadharDetailsDocsStatus] = useState<any>(
    {}
  );

  const [userAadharPhotoLink, setuserAadharPhotoLink] = useState("");
  const [UserAadharNumber, setUserAadharNumber] = useState("");
  const [AadharDetailsFetchStatus, setAadharDetailsFetchStatus] =
    useState(false);
  const [ActivityDetailsFetchStatus, setActivityDetailsFetchStatus] =
    useState(false);
  const [showDocsImage, setShowDocsImage] = useState("");
  const [askForClarification, setaskForClarification] = useState("");
  const [finalStatusRejectBtnDisabled, setfinalStatusRejectBtnDisabled] =
    useState(false);
  const [finalStatusApprovedBtnDisabled, setfinalStatusApprovedBtnDisabled] =
    useState(false);
  // to Navigate or get the data from other sibling components
  const location = useLocation();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "start",
    color: theme.palette.text.secondary,
    boxShadow: "none",
  }));

  const handleUserGstProfile = async () => {
    setexpandBusinessDetails(!expandBusinessDetails);

    if (expandBusinessDetails) {
      setBusinessDetailsFetchStatus(true);
      if (token !== "" && token !== undefined) {
        const setToken = await ApiService.setToken(token);
        const responseData: any = await ApiService.get(
          "/admin/userbusinessdetail",
          {
            id: location.state ? location.state.user_id : "",
          }
        );
        if (responseData.status === 200) {
          setuserBusinessDetails(responseData.data.result.restResult);
          setbusinessDetailsDocs(responseData.data.result.resultFiles);
          setBusinessDetailsDocsStatus(responseData.data.result.status);
          setBusinessDetailsFetchStatus(false);
        } else {
          console.log(responseData);
        }
      }
    }
  };
  const handleClose = () => {
    setbtndesabled(true);
    setGstRemarksDialog(false);
  };
  const handleDocsImage = (image: any) => {
    setShowDocsImage(image);
    setDocsDialog(!DocsDialog);
  };
  const [Remark, setRemark] = useState("");
  const [btndesabled, setbtndesabled] = useState(true);
  const handleOnchangeSetRemarks = (e: any) => {
    if (e.target.value !== "") {
      setRemark(e.target.value);
      setbtndesabled(false);
    } else {
      setbtndesabled(true);
    }
  };
  // const awsbucketImage = (valueImage: any) => {
  //   AWS.config.update({
  //     accessKeyId: "AKIAXMCKOYBF2KUWPN64",
  //     secretAccessKey: "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",
  //     region: "ap-south-1",
  //   });

  //   const s3 = new AWS.S3();
  //   const imageUrl = valueImage;
  //   const generatePresignedUrl = (bucketName: any, key: any) => {
  //     const params = {
  //       Bucket: "apassetbucket",
  //       Key: "https://apassetbucket.s3.ap-south-1.amazonaws.com/4809957019.pdf",
  //       Expires: 60, // URL expiration time in seconds (e.g., 1 minute)
  //     };

  //     return s3.getSignedUrl("getObjectd", params);
  //   };

  //   // Example usage
  //   // const bucketName = "YOUR_S3_BUCKET_NAME";
  //   // const objectKey = "YOUR_S3_OBJECT_KEY";

  //   const presignedUrl = generatePresignedUrl(
  //     "apassetbucket",
  //     "https://apassetbucket.s3.ap-south-1.amazonaws.com/4809957019.pdf"
  //   );
  //   console.log("Presigned URL:jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj", presignedUrl);
  // };
  const openDialogForGst = (value: any) => {
    console.log(value, "check value what exactly it is");
    setaskForClarification(value);
    setGstRemarksDialog(true);
  };
  const handleUserAdharProfile = async () => {
    setexpandAadharDetails(!expandAadharDetails);
    if (expandAadharDetails) {
      setAadharDetailsFetchStatus(true);
      if (token !== "" && token !== undefined) {
        const setToken = await ApiService.setToken(token);
        const responseData: any = await ApiService.get(
          "/admin/businessrepresentativedetail",
          {
            id: location.state ? location.state.user_id : "",
          }
        );
        if (responseData.status === 200) {
          setuserAadharDetails(responseData.data.result.restResult);
          setUserAadharNumber(
            responseData.data.result.resultAadharPhoto.aadharNumber
          );
          setuserAadharPhotoLink(
            responseData.data.result.resultAadharPhoto.aadharPhotoLink
          );
          setAadharDetailsDocs(responseData.data.result.resultFiles);
          setAadharDetailsDocsStatus(responseData.data.result.status);
          setAadharDetailsFetchStatus(false);
        } else {
          console.log(responseData);
        }
      }
    }
  };

  const ApprovedDocs = async (filename: string) => {
    let flag;
    if (filename === "aadharFileUrl") {
      flag = "AdminAadhaarS1Verified";
    } else if (filename === "aadharBackUrl") {
      flag = "AdminAadhaarS2Verified";
    } else if (filename === "PANFile") {
      flag = "AdminPanVerified";
    } else {
      flag = "AdminGSTVerified";
    }
    if (token !== "" && token !== undefined) {
      let value = {
        _flag: flag ? flag : "",
        status: "Approved",
        id: location.state ? location.state.user_id : "",
      };
      // BusinessApprovedDocs(value, token);
      console.log(filename);

      if (token !== "" && token !== undefined && value) {
        const setToken = await ApiService.setToken(token);
        const responseData: any = await ApiService.post(
          "/admin/approveDocument",
          value
        );
        if (responseData.status === 200) {
          if (filename === "aadharFileUrl") {
            setStatus1(true);
          } else if (filename === "aadharBackUrl") {
            setStatus2(true);
          } else if (filename === "PANFile") {
            setStatus3(true);
          } else if (filename === "AdminGSTVerified") {
            setStatus0(true);
          }
        } else {
          return responseData;
        }
      }
    }
  };
  const RejectDocs = () => {
    let flag;
    let docNameKey;
    if (askForClarification === "aadharFileUrl") {
      flag = "AdminAadhaarS1Verified";
      docNameKey = "Admin_AadhaarS1_Verification_Clarification";
      setStatus1(false);
    } else if (askForClarification === "aadharBackUrl") {
      flag = "AdminAadhaarS2Verified";
      docNameKey = "Admin_AadhaarS2_Verification_Clarification";
      setStatus2(false);
    } else if (askForClarification === "PANFile") {
      flag = "AdminPanVerified";
      docNameKey = "Admin_Pan_Verification_Clarification";
      setStatus3(false);
    } else {
      flag = "AdminGSTVerified";
      setStatus0(false);
      docNameKey = "Admin_GST_Verification_Clarification";
    }
    if (token !== "" && token !== undefined) {
      let value = {
        filename: flag ? flag : "",
        status: "Rejected",
        id: location.state ? location.state.user_id : "",
        docNameKey: docNameKey,
        clarification: Remark,
      };
      BusinessRejectedDocs(value, token);
      setGstRemarksDialog(false);
    }
  };
  const changeTextAdharProfile = (value: any) => {
    console.log(value);
    if (value === "aadharCO") {
      return "Care_of";
    } else if (value === "aadharGender") {
      return "Gender";
    } else if (value === "aadharCountry") {
      return "Country";
    } else if (value === "aadharPO") {
      return "PO";
    } else if (value === "nameInAadhaar") {
      return "Name";
    } else if (value === "distInAadhar") {
      return "Distict";
    } else if (value === "aadharPincode") {
      return "Pincode";
    } else if (value === "aadharNumber") {
      return "Aadhar No";
    } else if (value === "aadharDOB") {
      return "Date of Birth";
    } else if (value === "_id") {
      return "Id";
    } else if (value === "aadharState") {
      return "State";
    } else if (value === "aadharHouse") {
      return "House";
    } else if (value === "aadharStreet") {
      return "Street";
    }
  };
  const handleOnClickFinalStatus = async (value: any) => {
    if (token !== "" && token !== undefined) {
      let body = {
        key: value,
        id: location.state ? location.state.user_id : "",
      };

      const newToken = await ApiService.setToken(token);
      const responseData: any = await ApiService.post(
        "/admin/getAffiliates",
        body
      );
      if (responseData.status === 200) {
        if (value === "Approved") {
          setfinalStatusApprovedBtnDisabled(true);
        } else {
          setfinalStatusRejectBtnDisabled(true);
        }
        navigate("/app/userlist");
      } else {
        if (value === "Approved") {
          setfinalStatusApprovedBtnDisabled(false);
        } else {
          setfinalStatusRejectBtnDisabled(false);
        }
        console.log(responseData);
      }
    }
  };
  return (
    <Box
      justifyContent={"center"}
      component={"div"}
      sx={{
        // backgroundColor: "#F7F9FB",
        minHeight: "80vh",
        height: "100%",
        pb: 4,
      }}
    >
      <Stack>
        <Typography
          fontFamily={"Public Sans"}
          fontSize={"20px"}
          component={"h4"}
          sx={{ ml: 8, mt: 4 }}
          lineHeight={"36px"}
          fontWeight={700}
          fontStyle={"normal"}
          color={"#000000"}
        >
          Request Reference Number #
          {location.state !== undefined ? location.state.referenceNumber : ""}
        </Typography>
        {/* <Typography
          fontFamily={"Public Sans"}
          fontSize={12}
          sx={{ ml: 8 }}
          fontWeight={600}
          fontStyle={"normal"}
        >
          {" "}
          {location.state !== undefined ? location.state.referenceNumber : ""}
        </Typography> */}
      </Stack>
      <Box boxShadow={0} mx={8} my={3}>
        <Accordion>
          <AccordionSummary
            onClick={handleUserGstProfile}
            expandIcon={
              <>
                <ExpandMoreIcon />
              </>
            }
          >
            <Typography
              fontFamily={"Public Sans"}
              fontSize={"19px"}
              mx={4}
              mt={1}
              component={"h5"}
              fontWeight={700}
              lineHeight={"30px"}
              color={"#334A67"}
            >
              Business Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {businessDetailsFetchStatus ? (
              <Box display={"flex"} justifyContent={"center"}>
                <Stack spacing={2}>
                  <CircularProgress color="success" />
                  <Typography
                    component="div"
                    fontFamily={"Public Sans"}
                    fontSize={12}
                    fontWeight={600}
                    lineHeight={"30px"}
                    color={"#556486"}
                  >
                    Please wait
                  </Typography>
                </Stack>
              </Box>
            ) : (
              <Box>
                <Typography
                  component="div"
                  fontFamily={"Public Sans"}
                  fontSize={12}
                  mt={-4.5}
                  mx={4}
                  fontWeight={600}
                  lineHeight={"30px"}
                  color={"#556486"}
                >
                  Please Verify Details
                </Typography>

                <Grid
                  container
                  sx={{
                    // m: 2,
                    mt: 1,
                    px: 2,
                    "& .MuiPaper-root": {
                      px: "2px",
                      py: 0,
                    },
                  }}
                  // spacing={{ xs: 2, md: 0 }}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  {userBusinessDetails &&
                    Object.keys(userBusinessDetails).map(
                      (name: any, index: any) => (
                        <Grid
                          sx={{ px: "12px", py: "7px" }}
                          xs={2}
                          sm={4}
                          md={4}
                          key={index}
                        >
                          <Item>
                            <Typography
                              sx={{ m: 0, p: 0 }}
                              fontFamily={"Public Sans"}
                              fontSize={11}
                              color={"#919EAB"}
                            >
                              {/* {item.name} */}
                              {name}
                            </Typography>
                            <Typography
                              fontSize={"14px"}
                              fontStyle={"normal"}
                              fontFamily={"Public Sans"}
                              color={"#212B36"}
                              fontWeight={600}
                              sx={{ m: 0, p: 0 }}
                            >
                              {userBusinessDetails[name]
                                ? userBusinessDetails[name]
                                : "N/A"}
                            </Typography>
                          </Item>
                        </Grid>
                      )
                    )}

                  <Grid
                    container
                    sx={{
                      py: 2,
                      "& .MuiPaper-root": {
                        padding: "2px",
                      },
                    }}
                  >
                    {Object.keys(businessDetailsDocs).map(
                      (value: any, index: any) => {
                        return (
                          <Grid
                            // style={{
                            //   padding: "12px",
                            // }}
                            sx={{ px: "12px", py: "7px" }}
                            xs={2}
                            sm={4}
                            md={4}
                            key={index}
                          >
                            <Item>
                              <Typography
                                fontFamily={"Public Sans"}
                                fontSize={11}
                                color={"#919EAB"}
                                mb={1}
                              >
                                Business Doucment {index + 1}
                              </Typography>
                              <img
                                src={businessDetailsDocs[value]}
                                onClick={() =>
                                  handleDocsImage(businessDetailsDocs[value])
                                }
                                alt=""
                                height={"89px"}
                                width={"150px"}
                              />

                              {BusinessDetailsDocsStatus &&
                              BusinessDetailsDocsStatus.AdminGSTVerified !==
                                "Approved" ? (
                                <Box>
                                  {!Status0 ? (
                                    <Button
                                      size="small"
                                      onClick={() =>
                                        ApprovedDocs("AdminGSTVerified")
                                      }
                                      sx={{
                                        mt: 2,
                                        textTransform: "none",
                                        backgroundColor:
                                          "rgba(0, 171, 85, 0.16)",
                                        borderRadius: "8px",
                                        px: "48px",
                                        fontWeight: 700,
                                        fontSize: "13px",
                                        lineHeight: "22px",
                                        color: "rgba(0, 123, 85, 1)",
                                        "&:hover": {
                                          backgroundColor:
                                            "rgba(0, 171, 85, 0.16)",
                                        },
                                      }}
                                    >
                                      Approve
                                    </Button>
                                  ) : (
                                    <Button
                                      size="small"
                                      disabled={Status0}
                                      sx={{
                                        mt: 2,
                                        textTransform: "none",
                                        backgroundColor: "white",
                                        borderRadius: "8px",
                                        px: "48px",
                                        fontWeight: 700,
                                        fontSize: "13px",
                                        lineHeight: "22px",
                                        color: "rgba(0, 123, 85, 1)",
                                        "&:hover": {
                                          backgroundColor: "white",
                                        },
                                        border: "1px solid green",
                                      }}
                                    >
                                      <TaskAltIcon
                                        fontSize="small"
                                        sx={{ px: 2 }}
                                      />
                                    </Button>
                                  )}
                                </Box>
                              ) : (
                                <Box>
                                  <Button
                                    size="small"
                                    disabled={true}
                                    sx={{
                                      mt: 1,
                                      textTransform: "none",
                                      px: 2,
                                      backgroundColor:
                                        "rgba(145, 158, 171, 0.24)",
                                      borderRadius: "8px",
                                      fontWeight: 700,
                                      fontSize: "13px",
                                      lineHeight: "22px",
                                      color: "dark",
                                      "&:hover": {
                                        backgroundColor:
                                          "rgba(145, 158, 171, 0.24)",
                                      },
                                      // border: "1px solid green",
                                    }}
                                  >
                                    <Typography
                                      fontSize={14}
                                      fontFamily={"Public Sans"}
                                      fontWeight={500}
                                      // fontStyle={"normal"}
                                    >
                                      {" "}
                                      Already Approved
                                    </Typography>
                                  </Button>
                                </Box>
                              )}

                              <Box>
                                <Button
                                  size="small"
                                  sx={{
                                    mt: 2,
                                    textTransform: "none",
                                    borderRadius: "8px",
                                    px: "9px",
                                    color: "black",
                                    fontWeight: 700,
                                    fontSize: "13px",
                                    lineHeight: "22px",
                                    border:
                                      "2px solid rgba(145, 158, 171, 0.32)",
                                  }}
                                  onClick={() => openDialogForGst(value)}
                                >
                                  Ask for Clarification
                                </Button>
                              </Box>
                            </Item>
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                </Grid>
                <Dialog open={DocsDialog} style={{ borderRadius: "10px" }}>
                  <Container sx={{ pt: 2 }}>
                    <Stack
                      display={"flex"}
                      direction={"row"}
                      justifyContent={"center"}
                    >
                      <img
                        src={showDocsImage !== "" ? showDocsImage : ImageRatio}
                        style={{
                          height: "400px",
                          width: "500px",
                        }}
                        alt=""
                      />
                    </Stack>
                  </Container>
                  <DialogActions
                    sx={{
                      justifyContent: "end",
                      textAlign: "end",
                      px: 4,
                      mb: 2,
                    }}
                  >
                    <Button
                      sx={{ px: 4 }}
                      style={{
                        fontFamily: "Public Sans",
                        color: "white",
                        width: "auto",
                        fontSize: "14px",
                        backgroundColor: "#303981",
                        textTransform: "none",
                        borderRadius: "8px",
                        fontWeight: "700",
                      }}
                      onClick={() => setDocsDialog(false)}
                    >
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </Box>
            )}
          </AccordionDetails>
          {/*  */}
          <Dialog onClose={handleClose} open={GstRemarksDialog}>
            <Box sx={{ mx: 4, my: 2 }}>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"center"}
              >
                <Typography
                  fontFamily={"Public Sans"}
                  fontSize={"20px"}
                  component={"h4"}
                  sx={{ my: 2 }}
                  lineHeight={"36px"}
                  fontWeight={700}
                  fontStyle={"normal"}
                  color={"rgba(51, 74, 103, 1)"}
                >
                  Are you sure ?
                </Typography>
              </Stack>
              <Stack spacing={1}>
                <Typography color={"rgba(85, 100, 134, 1)"}>
                  * Please mention valid reason for rejection
                </Typography>
                <TextField
                  label="Remarks"
                  size="small"
                  onChange={handleOnchangeSetRemarks}
                  type="text"
                  name="UserAadharNumber"
                  InputProps={{
                    style: {
                      borderRadius: "8px",
                      borderColor: "#919EAB",
                    },
                  }}
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 40,
                      minWidth: "23vw",
                    },
                    "& .MuiFormLabel-root": {
                      color: "#919EAB",
                      fontFamily: "Public Sans",
                      fontWeight: "500",
                      fontSize: "14px",
                      Height: "20px",
                    },
                  }}
                />{" "}
              </Stack>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"center"}
                spacing={2}
                my={4}
              >
                <Button
                  size="small"
                  onClick={handleClose}
                  sx={{
                    mt: 4,
                    textTransform: "none",
                    backgroundColor: "rgba(85, 100, 134, 1)",
                    borderRadius: "8px",
                    px: "44px",
                    fontWeight: 700,
                    fontSize: "13px",
                    lineHeight: "22px",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "rgba(85, 100, 134, 1)",
                    },
                  }}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  onClick={RejectDocs}
                  disabled={btndesabled}
                  sx={{
                    mt: 2,
                    textTransform: "none",
                    backgroundColor: "rgba(255, 86, 48, 1)",
                    borderRadius: "8px",
                    px: "44px",
                    fontWeight: 700,
                    fontSize: "13px",
                    lineHeight: "22px",

                    color: "white",
                    "&:hover": {
                      backgroundColor: "rgba(255, 86, 48, 1)",
                    },
                  }}
                >
                  Reject
                </Button>
              </Stack>
            </Box>
          </Dialog>
        </Accordion>
      </Box>

      {/*  */}
      <Box
        boxShadow={0}
        mx={8}
        my={3}
        sx={{ borderRadius: "50px", backgroundColor: "white" }}
      >
        <Accordion>
          <AccordionSummary
            onClick={handleUserAdharProfile}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography
              fontFamily={"Public Sans"}
              fontSize={"19px"}
              component={"h5"}
              fontWeight={700}
              mx={4}
              py={1.5}
              lineHeight={"30px"}
              color={"#334A67"}
            >
              Authorised Business Representative Details
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {AadharDetailsFetchStatus ? (
              <Box display={"flex"} justifyContent={"center"}>
                <Stack spacing={2}>
                  <CircularProgress color="success" />
                  <Typography
                    component="div"
                    fontFamily={"Public Sans"}
                    fontSize={12}
                    fontWeight={600}
                    lineHeight={"30px"}
                    color={"#556486"}
                  >
                    Please wait
                  </Typography>
                </Stack>
              </Box>
            ) : (
              <Box>
                <Typography
                  component="div"
                  fontFamily={"Public Sans"}
                  fontSize={12}
                  mt={-4.5}
                  mx={4}
                  fontWeight={600}
                  lineHeight={"30px"}
                  color={"#556486"}
                >
                  Please Verify Details
                </Typography>
                <>
                  <Box component="form" sx={{ ml: 4 }}>
                    <Stack direction={"row"} sx={{ my: 1.5 }}>
                      <TextField
                        disabled
                        label="Your Aadhar Number"
                        value={UserAadharNumber}
                        size="small"
                        type="text"
                        name="UserAadharNumber"
                        InputProps={{
                          style: {
                            borderRadius: "8px",
                            borderColor: "#919EAB",
                          },
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            height: 40,
                            minWidth: "23vw",
                          },
                          "& .MuiFormLabel-root": {
                            color: "#919EAB",
                            fontFamily: "Public Sans",
                            fontWeight: "500",
                            fontSize: "14px",
                            Height: "20px",
                          },
                        }}
                      />{" "}
                      <Stack
                        direction="row"
                        spacing={0.5}
                        sx={{ ml: "20px", mt: 1 }}
                      >
                        <>
                          <DoneIcon
                            style={{ fontSize: "15px", color: "#36B37E" }}
                          />{" "}
                          <Typography
                            fontWeight={600}
                            fontSize={11}
                            fontFamily={"Public Sans"}
                            color={"#36B37E"}
                          >
                            {" "}
                            Business Details Fetched SuccessFully
                          </Typography>
                        </>
                      </Stack>
                    </Stack>
                    <>
                      <Stack
                        display={"flex"}
                        direction={"row"}
                        spacing={0.5}
                        sx={{ my: 1 }}
                      >
                        <img
                          src={RemainingIcon}
                          style={{ height: "16px", width: "16px" }}
                          alt=""
                        />
                        <Typography
                          fontSize={12}
                          color={"var(--text-light-secondary, #637381)"}
                          fontStyle={"normal"}
                          fontFamily={"Public Sans"}
                          fontWeight={400}
                        >
                          {" "}
                          Remaining attempt : 4
                        </Typography>
                      </Stack>
                    </>
                    <Box sx={{ my: 3.5 }}>
                      <Grid
                        display={"flex"}
                        direction={"row"}
                        sx={{ fontSize: "12px" }}
                      >
                        <Grid xs={8} container>
                          <Typography
                            component="div"
                            fontFamily={"Public Sans"}
                            fontSize={19}
                            fontWeight={700}
                            lineHeight={"30px"}
                            color={"#212B36"}
                          >
                            <Grid
                              container
                              sx={{
                                m: 0,
                                mt: 0.5,
                                p: 0,
                                "& .MuiPaper-root": {
                                  // padding: "0px",
                                  py: "2px",
                                },
                              }}
                              spacing={{ xs: 3, md: 3 }}
                              columns={{ xs: 4, sm: 8, md: 12 }}
                            >
                              {/* {verifiedUserAdharData.map((name, index) => ( */}
                              {/* {Array.from(Array(12)).map((_, index) => ( */}
                              {Object.keys(userAadharDetails).map(
                                (name: any, index: any) => (
                                  <Grid
                                    container
                                    display={"flex"}
                                    style={{
                                      padding: "8px",
                                    }}
                                    // sx={{ my: 0, p: 0 }}
                                    xs={2}
                                    sm={4}
                                    md={4}
                                    key={index}
                                  >
                                    <Item>
                                      <Typography
                                        fontFamily={"Public Sans"}
                                        fontSize={11}
                                        color={"#919EAB"}
                                      >
                                        {changeTextAdharProfile(name)}
                                      </Typography>
                                      <Typography
                                        fontSize={"14px"}
                                        fontStyle={"normal"}
                                        fontFamily={"Public Sans"}
                                        color={"#212B36"}
                                        fontWeight={600}
                                      >
                                        {userAadharDetails[name]
                                          ? userAadharDetails[name]
                                          : "N/A"}
                                      </Typography>
                                    </Item>
                                  </Grid>
                                )
                              )}
                              <Grid
                                container
                                sx={{
                                  py: 2,
                                }}
                                // spacing={{ xs: 2, md: 3 }}
                              >
                                {/* {Array.from(Array(3)).map((_, index) => ( */}
                                {Object.keys(AadharDetailsDocs).map(
                                  (value: any, index: any) => {
                                    return (
                                      <Grid
                                        style={{
                                          padding: "12px",
                                        }}
                                        xs={2}
                                        sm={4}
                                        md={4}
                                        key={index}
                                      >
                                        <Item>
                                          <Typography
                                            fontFamily={"Public Sans"}
                                            fontSize={11}
                                            color={"#919EAB"}
                                            mb={1}
                                          >
                                            Business Doucment {index + 1}
                                          </Typography>
                                          <img
                                            src={
                                              AadharDetailsDocs[value] !== ""
                                                ? AadharDetailsDocs[value]
                                                : ImageRatio
                                            }
                                            onClick={() =>
                                              handleDocsImage(
                                                AadharDetailsDocs[value]
                                              )
                                            }
                                            alt=""
                                            height={"89px"}
                                            width={"150px"}
                                          />
                                          {index === 0 ? (
                                            <>
                                              {AadharDetailsDocsStatus &&
                                              AadharDetailsDocsStatus.AdminAadhaarS1Verified !==
                                                "Approved" ? (
                                                <Box>
                                                  {!Status1 ? (
                                                    <Button
                                                      size="small"
                                                      onClick={() =>
                                                        ApprovedDocs(value)
                                                      }
                                                      sx={{
                                                        mt: 2,
                                                        textTransform: "none",
                                                        backgroundColor:
                                                          "rgba(0, 171, 85, 0.16)",
                                                        borderRadius: "8px",
                                                        px: "48px",
                                                        fontWeight: 700,
                                                        fontSize: "13px",
                                                        lineHeight: "22px",
                                                        color:
                                                          "rgba(0, 123, 85, 1)",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "rgba(0, 171, 85, 0.16)",
                                                        },
                                                      }}
                                                    >
                                                      Approve
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      size="small"
                                                      disabled={Status1}
                                                      sx={{
                                                        mt: 2,
                                                        textTransform: "none",
                                                        backgroundColor:
                                                          "white",
                                                        borderRadius: "8px",
                                                        px: "48px",
                                                        fontWeight: 700,
                                                        fontSize: "13px",
                                                        lineHeight: "22px",
                                                        color:
                                                          "rgba(0, 123, 85, 1)",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "white",
                                                        },
                                                        border:
                                                          "1px solid green",
                                                      }}
                                                    >
                                                      <TaskAltIcon
                                                        fontSize="small"
                                                        sx={{ px: 2 }}
                                                      />
                                                    </Button>
                                                  )}
                                                </Box>
                                              ) : (
                                                <Box>
                                                  <Button
                                                    size="small"
                                                    disabled={true}
                                                    sx={{
                                                      mt: 2,
                                                      textTransform: "none",
                                                      px: 2,
                                                      backgroundColor:
                                                        "rgba(145, 158, 171, 0.24)",
                                                      borderRadius: "8px",
                                                      fontWeight: 700,
                                                      fontSize: "13px",
                                                      lineHeight: "22px",
                                                      color: "dark",
                                                      "&:hover": {
                                                        backgroundColor:
                                                          "rgba(145, 158, 171, 0.24)",
                                                      },
                                                      // border: "1px solid green",
                                                    }}
                                                  >
                                                    <Typography
                                                      fontSize={14}
                                                      fontFamily={"Public Sans"}
                                                      fontWeight={500}
                                                      // fontStyle={"normal"}
                                                    >
                                                      {" "}
                                                      Already Approved
                                                    </Typography>
                                                  </Button>
                                                </Box>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}

                                          {/* {index === 1 ? (
                                            <Box>
                                              {!Status2 ? (
                                                <Button
                                                  size="small"
                                                  onClick={() =>
                                                    ApprovedDocs(value)
                                                  }
                                                  sx={{
                                                    mt: 2,
                                                    textTransform: "none",
                                                    backgroundColor:
                                                      "rgba(0, 171, 85, 0.16)",
                                                    borderRadius: "8px",
                                                    px: "48px",
                                                    fontWeight: 700,
                                                    fontSize: "13px",
                                                    lineHeight: "22px",
                                                    color:
                                                      "rgba(0, 123, 85, 1)",
                                                    "&:hover": {
                                                      backgroundColor:
                                                        "rgba(0, 171, 85, 0.16)",
                                                    },
                                                  }}
                                                >
                                                  Approve
                                                </Button>
                                              ) : (
                                                <Button
                                                  size="small"
                                                  disabled={Status2}
                                                  sx={{
                                                    mt: 2,
                                                    textTransform: "none",
                                                    backgroundColor: "white",
                                                    borderRadius: "8px",
                                                    px: "48px",
                                                    fontWeight: 700,
                                                    fontSize: "13px",
                                                    lineHeight: "22px",
                                                    color:
                                                      "rgba(0, 123, 85, 1)",
                                                    "&:hover": {
                                                      backgroundColor: "white",
                                                    },
                                                    border: "1px solid green",
                                                  }}
                                                >
                                                  <TaskAltIcon
                                                    fontSize="small"
                                                    sx={{ px: 2 }}
                                                  />
                                                </Button>
                                              )}
                                            </Box>
                                          ) : (
                                            ""
                                          )} */}
                                          {index === 1 ? (
                                            <>
                                              {AadharDetailsDocsStatus &&
                                              AadharDetailsDocsStatus.AdminAadhaarS2Verified !==
                                                "Approved" ? (
                                                <Box>
                                                  {!Status2 ? (
                                                    <Button
                                                      size="small"
                                                      onClick={() =>
                                                        ApprovedDocs(value)
                                                      }
                                                      sx={{
                                                        mt: 2,
                                                        textTransform: "none",
                                                        backgroundColor:
                                                          "rgba(0, 171, 85, 0.16)",
                                                        borderRadius: "8px",
                                                        px: "48px",
                                                        fontWeight: 700,
                                                        fontSize: "13px",
                                                        lineHeight: "22px",
                                                        color:
                                                          "rgba(0, 123, 85, 1)",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "rgba(0, 171, 85, 0.16)",
                                                        },
                                                      }}
                                                    >
                                                      Approve
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      size="small"
                                                      disabled={Status1}
                                                      sx={{
                                                        mt: 2,
                                                        textTransform: "none",
                                                        backgroundColor:
                                                          "white",
                                                        borderRadius: "8px",
                                                        px: "48px",
                                                        fontWeight: 700,
                                                        fontSize: "13px",
                                                        lineHeight: "22px",
                                                        color:
                                                          "rgba(0, 123, 85, 1)",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "white",
                                                        },
                                                        border:
                                                          "1px solid green",
                                                      }}
                                                    >
                                                      <TaskAltIcon
                                                        fontSize="small"
                                                        sx={{ px: 2 }}
                                                      />
                                                    </Button>
                                                  )}
                                                </Box>
                                              ) : (
                                                <Box>
                                                  <Button
                                                    size="small"
                                                    disabled={true}
                                                    sx={{
                                                      mt: 2,
                                                      textTransform: "none",
                                                      px: 2,
                                                      backgroundColor:
                                                        "rgba(145, 158, 171, 0.24)",
                                                      borderRadius: "8px",
                                                      fontWeight: 700,
                                                      fontSize: "13px",
                                                      lineHeight: "22px",
                                                      color: "dark",
                                                      "&:hover": {
                                                        backgroundColor:
                                                          "rgba(145, 158, 171, 0.24)",
                                                      },
                                                      // border: "1px solid green",
                                                    }}
                                                  >
                                                    <Typography
                                                      fontSize={14}
                                                      fontFamily={"Public Sans"}
                                                      fontWeight={500}
                                                      // fontStyle={"normal"}
                                                    >
                                                      {" "}
                                                      Already Approved
                                                    </Typography>
                                                  </Button>
                                                </Box>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          {/* {index === 2 ? (
                                            <Box>
                                              {!Status3 ? (
                                                <Button
                                                  size="small"
                                                  onClick={() =>
                                                    ApprovedDocs(value)
                                                  }
                                                  sx={{
                                                    mt: 2,
                                                    textTransform: "none",
                                                    backgroundColor:
                                                      "rgba(0, 171, 85, 0.16)",
                                                    borderRadius: "8px",
                                                    px: "48px",
                                                    fontWeight: 700,
                                                    fontSize: "13px",
                                                    lineHeight: "22px",
                                                    color:
                                                      "rgba(0, 123, 85, 1)",
                                                    "&:hover": {
                                                      backgroundColor:
                                                        "rgba(0, 171, 85, 0.16)",
                                                    },
                                                  }}
                                                >
                                                  Approve
                                                </Button>
                                              ) : (
                                                <Button
                                                  size="small"
                                                  disabled={Status3}
                                                  sx={{
                                                    mt: 2,
                                                    textTransform: "none",
                                                    backgroundColor: "white",
                                                    borderRadius: "8px",
                                                    px: "48px",
                                                    fontWeight: 700,
                                                    fontSize: "13px",
                                                    lineHeight: "22px",
                                                    color:
                                                      "rgba(0, 123, 85, 1)",
                                                    "&:hover": {
                                                      backgroundColor: "white",
                                                    },
                                                    border: "1px solid green",
                                                  }}
                                                >
                                                  <TaskAltIcon
                                                    fontSize="small"
                                                    sx={{ px: 2 }}
                                                  />
                                                </Button>
                                              )}
                                            </Box>
                                          ) : (
                                            ""
                                          )} */}
                                          {index === 2 ? (
                                            <>
                                              {AadharDetailsDocsStatus &&
                                              AadharDetailsDocsStatus.AdminAadhaarS2Verified !==
                                                "Approved" ? (
                                                <Box>
                                                  {!Status3 ? (
                                                    <Button
                                                      size="small"
                                                      onClick={() =>
                                                        ApprovedDocs(value)
                                                      }
                                                      sx={{
                                                        mt: 2,
                                                        textTransform: "none",
                                                        backgroundColor:
                                                          "rgba(0, 171, 85, 0.16)",
                                                        borderRadius: "8px",
                                                        px: "48px",
                                                        fontWeight: 700,
                                                        fontSize: "13px",
                                                        lineHeight: "22px",
                                                        color:
                                                          "rgba(0, 123, 85, 1)",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "rgba(0, 171, 85, 0.16)",
                                                        },
                                                      }}
                                                    >
                                                      Approve
                                                    </Button>
                                                  ) : (
                                                    <Button
                                                      size="small"
                                                      disabled={Status1}
                                                      sx={{
                                                        mt: 2,
                                                        textTransform: "none",
                                                        backgroundColor:
                                                          "white",
                                                        borderRadius: "8px",
                                                        px: "48px",
                                                        fontWeight: 700,
                                                        fontSize: "13px",
                                                        lineHeight: "22px",
                                                        color:
                                                          "rgba(0, 123, 85, 1)",
                                                        "&:hover": {
                                                          backgroundColor:
                                                            "white",
                                                        },
                                                        border:
                                                          "1px solid green",
                                                      }}
                                                    >
                                                      <TaskAltIcon
                                                        fontSize="small"
                                                        sx={{ px: 2 }}
                                                      />
                                                    </Button>
                                                  )}
                                                </Box>
                                              ) : (
                                                <Box>
                                                  <Button
                                                    size="small"
                                                    disabled={true}
                                                    sx={{
                                                      mt: 2,
                                                      textTransform: "none",
                                                      px: 2,
                                                      backgroundColor:
                                                        "rgba(145, 158, 171, 0.24)",
                                                      borderRadius: "8px",
                                                      fontWeight: 700,
                                                      fontSize: "13px",
                                                      lineHeight: "22px",
                                                      color: "grey",
                                                      "&:hover": {
                                                        backgroundColor:
                                                          "rgba(145, 158, 171, 0.24)",
                                                      },
                                                    }}
                                                  >
                                                    <Typography
                                                      fontSize={14}
                                                      fontFamily={"Public Sans"}
                                                      fontWeight={500}
                                                      // fontStyle={"normal"}
                                                    >
                                                      {" "}
                                                      Already Approved
                                                    </Typography>
                                                  </Button>
                                                </Box>
                                              )}
                                            </>
                                          ) : (
                                            ""
                                          )}
                                          <Box>
                                            <Button
                                              size="small"
                                              sx={{
                                                mt: 2,
                                                textTransform: "none",
                                                borderRadius: "8px",
                                                px: "9px",
                                                color: "black",
                                                fontWeight: 700,
                                                fontSize: "13px",
                                                lineHeight: "22px",
                                                border:
                                                  "2px solid rgba(145, 158, 171, 0.32)",
                                              }}
                                              onClick={() =>
                                                openDialogForGst(value)
                                              }
                                            >
                                              Ask for Clarification
                                            </Button>
                                          </Box>
                                        </Item>
                                      </Grid>
                                    );
                                  }
                                )}
                              </Grid>
                            </Grid>
                          </Typography>
                        </Grid>
                        <Grid xs={4} mt={-3}>
                          <Item>
                            <Avatar
                              alt=""
                              src={
                                userAadharPhotoLink
                                  ? userAadharPhotoLink
                                  : "/static/images/avatar/1.jpg"
                              }
                              sx={{ width: 100, height: 100 }}
                            />
                          </Item>
                        </Grid>
                      </Grid>
                    </Box>
                  </Box>
                </>
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
      {/*  */}
      <Box
        boxShadow={0}
        mx={8}
        my={3}
        sx={{ borderRadius: "50px", backgroundColor: "white" }}
      >
        <Accordion>
          <AccordionSummary
            onClick={handleUserAdharProfile}
            expandIcon={<ExpandMoreIcon />}
          >
            <Typography
              fontFamily={"Public Sans"}
              fontSize={"19px"}
              component={"h5"}
              fontWeight={700}
              mx={4}
              py={1.5}
              lineHeight={"30px"}
              color={"#334A67"}
            >
              Activity
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {ActivityDetailsFetchStatus ? (
              <Box display={"flex"} justifyContent={"center"}>
                <Stack spacing={2}>
                  <CircularProgress color="success" />
                  <Typography
                    component="div"
                    fontFamily={"Public Sans"}
                    fontSize={12}
                    fontWeight={600}
                    lineHeight={"30px"}
                    color={"#556486"}
                  >
                    Please wait
                  </Typography>
                </Stack>
              </Box>
            ) : (
              <Box>
                <Typography
                  component="div"
                  fontFamily={"Public Sans"}
                  fontSize={12}
                  mt={-4.5}
                  mx={4}
                  fontWeight={600}
                  lineHeight={"30px"}
                  color={"#556486"}
                >
                  Please Verify Details
                </Typography>
                <BusinessActivity />
              </Box>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
      <Box sx={{ mx: 8 }}>
        <Grid container>
          <Grid xs={10}>
            <Link to={"/app/userlist"} style={{ textDecoration: "none" }}>
              <Stack
                display={"flex"}
                direction={"row"}
                justifyContent={"start"}
              >
                <ArrowLeftIcon sx={{ mt: 0.15 }} />
                <Typography
                  component="div"
                  fontFamily={"Public Sans"}
                  fontSize={15}
                  fontWeight={700}
                  lineHeight={"26px"}
                  color={"Black"}
                >
                  Back
                </Typography>
              </Stack>{" "}
            </Link>
          </Grid>
          <Grid xs={2}>
            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"end"}
              spacing={2}
            >
              {" "}
              <Button
                size="small"
                onClick={() => handleOnClickFinalStatus("Rejected")}
                disabled={finalStatusRejectBtnDisabled}
                sx={{
                  mt: 2,
                  textTransform: "none",
                  backgroundColor: !finalStatusRejectBtnDisabled
                    ? "var(--error-main, #FF5630)"
                    : "rgba(145, 158, 171, 0.24)",

                  borderRadius: "8px",
                  display: "inline-flex",
                  padding: "11px 28px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  fontWeight: 700,
                  fontSize: "13px",
                  lineHeight: "22px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: !finalStatusRejectBtnDisabled
                      ? "var(--error-main, #FF5630)"
                      : "rgba(145, 158, 171, 0.24)",
                  },
                }}
              >
                Reject
              </Button>
              <Button
                size="small"
                disabled={finalStatusApprovedBtnDisabled}
                sx={{
                  mt: 2,
                  display: "inline-flex",
                  padding: "11px 22px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  textTransform: "none",
                  backgroundColor: !finalStatusApprovedBtnDisabled
                    ? "var(--success-main, #36B37E)"
                    : "rgba(145, 158, 171, 0.24)",
                  borderRadius: "8px",
                  fontWeight: 700,
                  fontSize: "13px",
                  lineHeight: "22px",
                  color: "white",
                  "&:hover": {
                    backgroundColor: !finalStatusApprovedBtnDisabled
                      ? "var(--success-main, #36B37E)"
                      : "rgba(145, 158, 171, 0.24)",
                  },
                }}
                onClick={() => handleOnClickFinalStatus("Approved")}
              >
                Approve
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BusinessReferenceNumber;
