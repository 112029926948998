import { Typography as Text } from "@mui/material";
export const Typography24 = (props: any) => {
  return (
    <Text
      fontFamily={"Public Sans"}
      fontSize={props.fontSize ? props.fontSize : "24px"}
      fontWeight={700}
      lineHeight={"36px"}
      sx={props.sx ? props.sx : ""}
      color={props.color ? props.color : ""}
      fontStyle={"normal"}
    >
      {props.text}
    </Text>
  );
};
export const Typography20 = (props: any) => {
  return (
    <Text
      fontFamily={"Public Sans"}
      fontSize={"20px"}
      fontWeight={props.fontWeight ? props.fontWeight : 700}
      lineHeight={"30px"}
      sx={props.sx ? props.sx : ""}
      color={props.color ? props.color : ""}
      fontStyle={"normal"}
    >
      {props.text}
    </Text>
  );
};
export const Typography18 = (props: any) => {
  return (
    <Text
      fontFamily={"Public Sans"}
      fontSize={"18px"}
      fontWeight={props.fontWeight ? props.fontWeight : 600}
      lineHeight={"18px"}
      sx={props.sx ? props.sx : ""}
      color={props.color ? props.color : ""}
      fontStyle={"normal"}
    >
      {props.text}
    </Text>
  );
};
export const Typography12 = (props: any) => {
  return (
    <Text
      fontFamily={"Public Sans"}
      fontSize={props.fontSize ? props.fontSize : "12px"}
      fontWeight={props.fontWeight ? props.fontWeight : 400}
      lineHeight={"18px"}
      sx={props.sx ? props.sx : ""}
      color={props.color ? props.color : "var(--text-light-secondary, #637381)"}
      fontStyle={"normal"}
    >
      {props.text}
    </Text>
  );
};
export const Typography14 = (props: any) => {
  return (
    <Text
      fontFamily={"Public Sans"}
      fontSize={"14px"}
      fontWeight={props.fontWeight ? props.fontWeight : 600}
      lineHeight={"22px"}
      sx={props.sx ? props.sx : ""}
      color={props.color ? props.color : "var(--text-light-secondary, #637381)"}
      fontStyle={"normal"}
    >
      {props.text}
    </Text>
  );
};
