import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import NetworkLockedIcon from "@mui/icons-material/NetworkLocked";
import LoginIcon from "@mui/icons-material/Login";
import { Link } from "react-router-dom";

const NotAuthorised = () => {
  return (
    <Box
      //   boxShadow={2}
      height={"80vh"}
      display={"flex"}
      justifyContent={"center"}
      flexDirection={"row"}
    >
      <Box
        boxShadow={1}
        sx={{ borderRadius: "8px", my: 4 }}
        display={"flex"}
        // justifyContent={"center"}
      >
        <Stack
          display={"flex"}
          //   justifyContent={"center"}
          spacing={2}
          sx={{ px: 8, mt: 16 }}
          textAlign={"center"}
        >
          <div>
            <Stack
              display={"flex"}
              direction={"row"}
              justifyContent={"center"}
              spacing={3}
            >
              <Typography
                fontFamily={"Public Sans"}
                fontWeight={700}
                fontSize={30}
                color={"rgba(48, 57, 129, 1)"}
              >
                Your are Not Authorised
              </Typography>
              <NetworkLockedIcon
                sx={{
                  color: "rgba(183, 29, 24, 1)",
                }}
                fontSize="large"
              />
            </Stack>
          </div>

          <Typography
            fontFamily={"Public Sans"}
            fontWeight={600}
            fontSize={20}
            color={"rgba(99, 115, 129, 1)"}
          >
            Please go to login page for access this application.
          </Typography>
          <Typography
            fontFamily={"Public Sans"}
            color={"rgba(99, 115, 129, 1)"}
            fontWeight={600}
            fontSize={20}
          >
            Thankyou for visit here
          </Typography>
          <div>
            <Stack
              display={"flex"}
              direction={"row"}
              spacing={2}
              justifyContent={"center"}
            >
              <Typography
                fontFamily={"Public Sans"}
                fontWeight={600}
                fontSize={20}
                color={"rgba(99, 115, 129, 1)"}
              >
                Login here
              </Typography>
              <Link to={"/"}>
                <LoginIcon sx={{ color: "green" }} fontSize="large" />
              </Link>
            </Stack>
          </div>
        </Stack>
      </Box>
    </Box>
  );
};
export default NotAuthorised;
