import React from "react";
import { Box, Stack } from "@mui/material";
import { Typography24 } from "../../components/Typography/Typography";

const ViewUsers = () => {
  return (
    <div>
      <Box
        justifyContent={"center"}
        component={"div"}
        sx={
          {
            // backgroundColor: "#F7F9FB",
            // margin: 4,
          }
        }
      >
        <Stack>
          <Typography24
            sx={{ ml: 8, mt: 2 }}
            color={"#000000"}
            text={"User Management"}
          ></Typography24>
        </Stack>
      </Box>
    </div>
  );
};

export default ViewUsers;
