import { Box } from "@mui/system";
import React from "react";

const ReportsExport = () => {
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      textAlign={"center"}
      mt={"30%"}
    >
      Working on reports (exports)
    </Box>
  );
};

export default ReportsExport;
