import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { onBoardingTermsPolicy } from "../../redux/slices/admin";
import { useDispatch } from "react-redux";
import DoneIcon from "@mui/icons-material/Done";
import { LoadingButton } from "@mui/lab";

const TermsAndPolicies = (props: any) => {
  const getData = useSelector((state: any) => state.user.user);
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const dispatch = useDispatch();
  const [btnDisabled, setbtnDisabled] = useState(true);
  const [SuccessMsg, setSuccessMsg] = useState(false);
  const [ErrorMsg, setErrorMsg] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState("");

  const [LoadingButtonDisabled, setLoadingButtonDisabled] = useState(false);
  const [agreementDocs, setagreementDocs] = useState<any>({
    termsOfService: "",
    privacyPolicy: "",
    disclaimer: "",
  });

  // on Change function for arrange data
  const handleOnChangeDocs = (event: any) => {
    setbtnDisabled(false);
    setSuccessMsg(false);
    setErrorMsg(false);
    const { name, value } = event.target;
    setagreementDocs((preve: any) => {
      return {
        ...preve,
        [name]: value,
      };
    });
  };

  const handleOnChangeUpdateTermsPolices = (e: any) => {
    e.preventDefault();
    setLoadingButtonDisabled(true);
    setErrorMsg(false);
    setErrorMessage("");
    setbtnDisabled(true);
    const axiosConfig = {
      headers: {
        token: props ? props.props : "",
      },
    };
    const fetchData = axios
      .post(`${REACT_APP_BASE_URL}/admin/setLimits`, agreementDocs, axiosConfig)
      .then((res) => {
        if (res.status === 200 && res.data.Active === true) {
          dispatch(onBoardingTermsPolicy(res.data.result));
          setLoadingButtonDisabled(false);
          setSuccessMsg(true);
          setbtnDisabled(true);
        }
      })
      .catch((err) => {
        setErrorMsg(true);
        setErrorMessage(
          err.response.data.error ? err.response.data.error : err.message
        );
        setLoadingButtonDisabled(false);
        setbtnDisabled(false);
      });
  };
  // set Data from store to terms and policies textfield through mounted useEffect hook
  useEffect(() => {
    let value = {
      termsOfService: getData.termsOfService,
      privacyPolicy: getData.privacyPolicy,
      disclaimer: getData.disclaimer,
    };
    setagreementDocs(value);
  }, [getData]);

  return (
    <Box
      boxShadow={1}
      mx={4}
      my={1}
      sx={{ borderRadius: "8px", backgroundColor: "white" }}
    >
      <Stack m={4}>
        <Typography
          color={"#334A67"}
          fontFamily={"Public Sans"}
          fontSize={"20px"}
          fontWeight={700}
        >
          Legal
        </Typography>
        <Box component={"form"} onSubmit={handleOnChangeUpdateTermsPolices}>
          <Stack spacing={2} mt={3}>
            <TextField
              margin="normal"
              multiline
              rows={3}
              InputLabelProps={{
                style: {
                  color: "#919EAB",
                  fontFamily: "Public Sans",
                  fontWeight: "500",
                  fontSize: 14,
                },
              }}
              name="termsOfService"
              id="termsOfService"
              label="Terms of Service"
              value={
                agreementDocs.termsOfService
                  ? agreementDocs.termsOfService
                  : "N/A"
              }
              type="text"
              size="small"
              onChange={handleOnChangeDocs}
              InputProps={{
                style: {
                  borderRadius: "8px",
                  borderColor: "grey",
                  fontSize: "12px",
                  fontFamily: "Public Sans",
                  fontWeight: 500,
                  lineHeight: "18px",
                },
              }}
            />
            <TextField
              margin="normal"
              multiline
              rows={3}
              InputLabelProps={{
                style: {
                  color: "#919EAB",
                  fontFamily: "Public Sans",
                  fontWeight: "500",
                  fontSize: 14,
                },
              }}
              name="privacyPolicy"
              label="Private Policy"
              id="privacyPolicy"
              type="type"
              value={
                agreementDocs.privacyPolicy
                  ? agreementDocs.privacyPolicy
                  : "N/A"
              }
              onChange={handleOnChangeDocs}
              size="small"
              InputProps={{
                style: {
                  borderRadius: "8px",
                  borderColor: "grey",
                  fontSize: "12px",
                  fontFamily: "Public Sans",
                  fontWeight: 500,
                  lineHeight: "18px",
                },
              }}
            />
            <TextField
              margin="normal"
              multiline
              rows={3}
              InputLabelProps={{
                style: {
                  color: "#919EAB",
                  fontFamily: "Public Sans",
                  fontWeight: "500",
                  fontSize: 14,
                },
              }}
              name="disclaimer"
              label="Disclaimer"
              value={
                agreementDocs.disclaimer ? agreementDocs.disclaimer : "N/A"
              }
              onChange={handleOnChangeDocs}
              id="disclaimer"
              type="text"
              size="small"
              InputProps={{
                style: {
                  borderRadius: "8px",
                  borderColor: "grey",
                  fontSize: "12px",
                  fontFamily: "Public Sans",
                  fontWeight: 500,
                  lineHeight: "18px",
                },
              }}
            />
          </Stack>
          <Stack display={"flex"} direction={"row"} sx={{ mt: 4 }} spacing={2}>
            <LoadingButton
              size="small"
              disabled={btnDisabled}
              type="submit"
              sx={{
                mt: 3,
                minWidth: "25vw",
                borderRadius: "8px",
                fontSize: "14",
                fontFamily: "Public Sans",
                gap: "8px",
                backgroundColor: btnDisabled
                  ? "rgba(145, 158, 171, 0.24)"
                  : "#303981",
                textTransform: "none",
                color: btnDisabled ? "" : "white",
                "&:hover": {
                  backgroundColor: "#303981",
                },
              }}
              loading={LoadingButtonDisabled}
            >
              Update
            </LoadingButton>
            {SuccessMsg ? (
              <div>
                <Stack
                  display={"flex"}
                  direction={"row"}
                  spacing={0.5}
                  mt={0.6}
                >
                  <DoneIcon style={{ fontSize: "20px", color: "#36B37E" }} />{" "}
                  <Typography
                    fontWeight={600}
                    fontSize={14}
                    fontFamily={"Public Sans"}
                    color={"#36B37E"}
                  >
                    {" "}
                    Terms updated Successfully
                  </Typography>
                </Stack>
              </div>
            ) : (
              ""
            )}
            {ErrorMsg ? (
              <div>
                <Stack
                  display={"flex"}
                  direction={"row"}
                  spacing={0.5}
                  mt={0.6}
                >
                  <Typography
                    fontWeight={600}
                    fontSize={14}
                    fontFamily={"Public Sans"}
                    color={"red"}
                  >
                    {" "}
                    {ErrorMessage}
                  </Typography>
                </Stack>
              </div>
            ) : (
              ""
            )}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default TermsAndPolicies;
