import React, { useState } from "react";
import { Avatar, Stack, Box, Typography, Chip } from "@mui/material";
import { Link } from "react-router-dom";
import LogoSign from "../../assets/Assure_Logo.svg";
import OnBoardingToolsIcon from "../../assets/ic_analytics.svg";
import CircleIcon from "../../assets/CircleIcon.svg";
import { firstCharacterUppercase } from "../../utils/characterCases/Cases";
import PaymentIcon from "@mui/icons-material/Payment";
import { Collapse } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import APLogo from "../../assets/LogoSvg.svg";
import UserManagmentIcon from "../../assets/ic_user.svg";
import DashboardIcon from "../../assets/ic_dashboard.svg";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CategoryIcon from "@mui/icons-material/Category";
import { Typography12, Typography14 } from "../Typography/Typography";
import { useSelector } from "react-redux";
import PaymentsIcon from "@mui/icons-material/Payments";
import DiscountIcon from "@mui/icons-material/Discount";
import SmsIcon from "@mui/icons-material/Sms";
import { formatedData } from "../../utils/Validations/ValidationsFn";
const { version, lastUpdated } = require("../../../package.json");
const drawerWidth = 270;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  // justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
function Sidebars() {
  const userName = useSelector((state: any) => state.user.userData.username);
  const [userManagementMenu, setuserManagementMenu] = useState(false);
  const [PaymentsMenu, setPaymentsMenu] = useState(false);
  const [Accounting, setAccounting] = useState(false);

  const [paymentMenu, setpaymentMenu] = useState(false);
  const [open, setOpen] = React.useState(true);

  const handleDrawerClose = () => {
    setuserManagementMenu(false);
    setPaymentsMenu(false);
    setAccounting(false);
    setpaymentMenu(false);
    setOpen(!open);
  };
  function VersionDate() {
    return (
      <Box
        sx={{
          position: "sticky",
          bottom: 0,
          left: 0,
          right: 0,
          padding: 2,
          backgroundColor: "#fff",
          borderTop: "1px solid #ddd",
          textAlign: "center",
          zIndex: 1, // Ensure it stays above the scrolling content
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Center align vertically
          justifyContent: "center", // Center align horizontally
        }}
      >
        <Stack display={"flex"} direction={"row"} spacing={0.5}>
          <Typography
            fontWeight={600}
            color={"var(--text-light-secondary, #637381)"}
            fontFamily={"Public Sans"}
            fontSize={14}
          >
            Version -
          </Typography>
          <Typography
            fontWeight={700}
            color={"var(--text-light-secondary, #637381)"}
            fontFamily={"Public Sans"}
            fontSize={14}
          >
            {version || "0.0.0.0"}
          </Typography>
        </Stack>

        <Typography
          fontWeight={600}
          color={"var(--text-light-secondary, #637381)"}
          fontFamily={"Public Sans"}
          fontSize={11}
        >
          {(lastUpdated && formatedData(lastUpdated)) || ""}
        </Typography>
      </Box>
    );
  }
  return (
    <>
      <Drawer variant="permanent" open={open} anchor="left">
        <Box sx={{ backgroundColor: "white" }}>
          <DrawerHeader onClick={handleDrawerClose}>
            {!open ? (
              <img src={LogoSign} alt="" />
            ) : (
              <img src={APLogo} alt="" />
            )}
          </DrawerHeader>
        </Box>

        <List>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Box px={1}>
              <Box
                sx={{
                  borderRadius: "12px",
                  px: 0.5,
                  py: 3,
                  maxWidth: "240px",
                  backgroundColor: "rgba(145, 158, 171, 0.08)",
                }}
              >
                <Stack direction={"row"} display={"flex"} spacing={2}>
                  <Avatar />
                  <ListItemText
                    primary={
                      <Stack>
                        <Typography14
                          text={
                            userName ? firstCharacterUppercase(userName) : "N/A"
                          }
                          color={"rgba(33, 43, 54, 1)"}
                        ></Typography14>
                        <Typography12 text={"Admin"}></Typography12>
                      </Stack>
                    }
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </Stack>
              </Box>
            </Box>
          </ListItem>
        </List>

        <List>
          {open ? (
            <Stack px={2} pt={1}>
              <Typography12 fontWeight={700} text={"GENERAL"}></Typography12>
            </Stack>
          ) : (
            ""
          )}

          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {<img src={DashboardIcon} alt="" />}
              </ListItemIcon>
              <ListItemText
                primary={
                  <Typography14
                    text={"Dashboard"}
                    fontSize={20}
                    // color={"rgba(48, 57, 129, 1)"}
                  ></Typography14>
                }
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
          {/* ))} */}
        </List>

        <List>
          {open ? (
            <Stack px={2} pt={1}>
              <Typography12 fontWeight={700} text={"MANAGEMENT"}></Typography12>
            </Stack>
          ) : (
            ""
          )}
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                {
                  <img
                    src={UserManagmentIcon}
                    height={"25px"}
                    width={"30px"}
                    alt=""
                  />
                }
              </ListItemIcon>
              <ListItemText
                onClick={() => setuserManagementMenu(!userManagementMenu)}
                primary={<Typography14 text={"User Management"}></Typography14>}
                sx={{ opacity: open ? 1 : 0 }}
              />
              {open ? (
                userManagementMenu ? (
                  <ExpandMoreIcon
                    onClick={() => setuserManagementMenu(!userManagementMenu)}
                  />
                ) : (
                  <ChevronRightIcon
                    onClick={() => setuserManagementMenu(!userManagementMenu)}
                  />
                )
              ) : (
                ""
              )}
            </ListItemButton>
            <Collapse in={userManagementMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* <ListItem> */}
                <Link to={"/app/userlist"} style={{ textDecoration: "none" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {
                        <img
                          src={CircleIcon}
                          height={"30px"}
                          width={"30px"}
                          alt=""
                        />
                      }
                    </ListItemIcon>

                    <ListItemText
                      primary={
                        <Typography14 text={"Businesses"}></Typography14>
                      }
                    />
                  </ListItemButton>
                </Link>
                <Link to={"/app/affliate"} style={{ textDecoration: "none" }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 2 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {
                        <img
                          src={CircleIcon}
                          height={"30px"}
                          width={"30px"}
                          alt=""
                        />
                      }
                    </ListItemIcon>

                    <ListItemText
                      primary={<Typography14 text={"Affiliate"}></Typography14>}
                    />
                  </ListItemButton>
                </Link>
              </List>
            </Collapse>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <PaymentsIcon />
              </ListItemIcon>
              <ListItemText
                onClick={() => setPaymentsMenu(!PaymentsMenu)}
                primary={<Typography14 text={"Payments"}></Typography14>}
                sx={{ opacity: open ? 1 : 0 }}
              />
              {open ? (
                PaymentsMenu ? (
                  <ExpandMoreIcon
                    onClick={() => setPaymentsMenu(!PaymentsMenu)}
                  />
                ) : (
                  <ChevronRightIcon
                    onClick={() => setPaymentsMenu(!PaymentsMenu)}
                  />
                )
              ) : (
                ""
              )}
            </ListItemButton>
            <Collapse in={PaymentsMenu} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* <ListItem> */}
                <Link
                  to={"/app/payment/list"}
                  style={{ textDecoration: "none" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <PaymentsIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={<Typography14 text={"Orders"}></Typography14>}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Link>
              </List>
            </Collapse>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <PaymentsIcon />
              </ListItemIcon>
              <ListItemText
                onClick={() => setAccounting(!Accounting)}
                primary={<Typography14 text={"Accounting"}></Typography14>}
                sx={{ opacity: open ? 1 : 0 }}
              />
              {open ? (
                Accounting ? (
                  <ExpandMoreIcon onClick={() => setAccounting(!Accounting)} />
                ) : (
                  <ChevronRightIcon
                    onClick={() => setAccounting(!Accounting)}
                  />
                )
              ) : (
                ""
              )}
            </ListItemButton>
            <Collapse in={Accounting} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* <ListItem> */}
                <Link
                  to={"/app/payment/revenue"}
                  style={{ textDecoration: "none" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <PaymentsIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography14 text={"AP Revenue"}></Typography14>
                      }
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Link>
                <Link
                  to={"/app/payment/revenue"}
                  style={{ textDecoration: "none" }}
                >
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 3,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      <PaymentsIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={<Typography14 text={"GST"}></Typography14>}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </Link>
              </List>
            </Collapse>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Link
              to={"/app/activities"}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img height={"25px"} width={"30px"} src={""} alt="" />
                    {/* <PaymentIcon sx={{ height: "25px", width: "30px" }} /> */}
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography14 text={"Activities"}></Typography14>}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          </ListItem>
          <ListItem disablePadding sx={{ display: "block" }}>
            <Link
              to={"/app/reports"}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img height={"25px"} width={"30px"} src={""} alt="" />
                    {/* <PaymentIcon sx={{ height: "25px", width: "30px" }} /> */}
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography14 text={"Reports"}></Typography14>}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
          </ListItem>
        </List>
        <List>
          {open ? (
            <Stack px={2} pt={1}>
              <Typography12 fontWeight={700} text={"TOOLS"}></Typography12>
            </Stack>
          ) : (
            ""
          )}
          <ListItem disablePadding sx={{ display: "block" }}>
            <Link
              to={"/app/onboarding"}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      height={"25px"}
                      width={"30px"}
                      src={OnBoardingToolsIcon}
                      alt=""
                    />
                    {/* <PaymentIcon sx={{ height: "25px", width: "30px" }} /> */}
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography14 text={"Onboarding"}></Typography14>}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
            <Link
              to={"/app/commission"}
              style={{
                textDecoration: "none",
              }}
            >
              <ListItem disablePadding sx={{ display: "block" }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <PersonAddIcon sx={{ height: "25px", width: "30px" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography14 text={"Affiliate"}></Typography14>}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ListItemButton>
              </ListItem>
            </Link>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <PaymentIcon sx={{ height: "25px", width: "30px" }} />
                </ListItemIcon>
                <ListItemText
                  onClick={() => setpaymentMenu(!paymentMenu)}
                  primary={<Typography14 text={"Payment"}></Typography14>}
                  sx={{ opacity: open ? 1 : 0 }}
                />
                {open ? (
                  paymentMenu ? (
                    <ExpandMoreIcon
                      onClick={() => setpaymentMenu(!paymentMenu)}
                    />
                  ) : (
                    <ChevronRightIcon
                      onClick={() => setpaymentMenu(!paymentMenu)}
                    />
                  )
                ) : (
                  ""
                )}
              </ListItemButton>
              <Collapse in={paymentMenu} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <Link to={"/app/fees"} style={{ textDecoration: "none" }}>
                    <ListItem style={{ paddingLeft: "40px" }}>
                      <ListItemText
                        primary={<Typography14 text={"AP Fee"}></Typography14>}
                      />
                    </ListItem>
                  </Link>
                  <Link to={"/app/duration"} style={{ textDecoration: "none" }}>
                    <ListItem style={{ paddingLeft: "40px" }}>
                      <ListItemText
                        primary={
                          <Typography14 text={"Order Validity"}></Typography14>
                        }
                      />
                    </ListItem>
                  </Link>
                </List>
              </Collapse>
            </ListItem>

            <Link
              to={"/app/businessnetwork"}
              style={{ textDecoration: "none" }}
            >
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <CategoryIcon height={"25px"} width={"30px"} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography14 text={"Business Network"}></Typography14>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>

            <Link to={"/app/coupons"} style={{ textDecoration: "none" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <DiscountIcon height={"25px"} width={"30px"} />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography14 text={"Coupons"}></Typography14>}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
            <Link to={"/app/coupons"} style={{ textDecoration: "none" }}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <SmsIcon height={"25px"} width={"30px"} />
                </ListItemIcon>
                <ListItemText
                  primary={
                    <Typography14 text={"SMS Email Templates"}></Typography14>
                  }
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        </List>
        {open ? <VersionDate /> : ""}
      </Drawer>
    </>
  );
}
export default Sidebars;
