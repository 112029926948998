import {
  Autocomplete,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import {
  Typography20,
  Typography24,
} from "../../components/Typography/Typography";
import { useState } from "react";

const CreateUser = () => {
  let array = [
    { label: "Name" },
    { label: "Type" },
    { label: "Personal Email" },
    { label: "Phone Number" },
    { label: "PAN (Individual/Company)" },
    { label: "Aadhar Card" },
    { label: "Address" },
    { label: "Pincode" },
    { label: "Business Location" },
    { label: "Bank" },
    { label: "IFSC Code" },
    { label: "Bank Account Number" },
  ];

  const [createUserList, setCreateUserList] = useState<any>({});
  const handleonChangeUserList = (e: any) => {
    const { name, value } = e.target;
    setCreateUserList((preve: any) => {
      return {
        ...preve,
        [name]: value,
      };
    });
  };
  console.log(createUserList);

  return (
    <div>
      <Box
        justifyContent={"center"}
        component={"div"}
        sx={
          {
            // backgroundColor: "#F7F9FB",
            // margin: 4,
          }
        }
      >
        <Stack>
          <Typography24
            sx={{ ml: 8, mt: 2 }}
            color={"#000000"}
            text={"User Management"}
          ></Typography24>
        </Stack>
        <Box
          boxShadow={1}
          mx={8}
          my={3}
          px={5}
          py={3.5}
          sx={{ borderRadius: "8px", backgroundColor: "white" }}
        >
          <Stack>
            <Typography20
              color={"#334A67"}
              fontFamily={"Public Sans"}
              fontSize={"20px"}
              fontWeight={700}
              text={"Create a User"}
            ></Typography20>
          </Stack>

          <Grid
            container
            sx={{
              // m: 2,
              mt: 1,
              ml: -1,
            }}
            // spacing={{ xs: 2, md: 0 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {/* {array.map((label: any, index: any) => ( */}
            <Grid xs={2} sm={4} md={4} px={1.5} py={2}>
              <TextField
                onChange={handleonChangeUserList}
                name="business_name"
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "400",
                    fontStyle: "normal",
                    fontSize: 14,
                    lineHeight: "14px",
                  },
                  "& .MuiFormHelperText-root": {
                    fontFamily: "Public Sans",
                    fontWeight: 400,
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    //   height: "40px",
                    borderColor: "#919EAB",
                    //   minWidth: "25vw",
                  },
                }}
                label={"Name"}
                fullWidth
              />
            </Grid>
            <Grid xs={2} sm={4} md={4} px={1.5} py={2}>
              <TextField
                onChange={handleonChangeUserList}
                name="type"
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "400",
                    fontStyle: "normal",
                    fontSize: 14,
                    lineHeight: "14px",
                  },
                  "& .MuiFormHelperText-root": {
                    fontFamily: "Public Sans",
                    fontWeight: 400,
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    //   height: "40px",
                    borderColor: "#919EAB",
                    //   minWidth: "25vw",
                  },
                }}
                label={"Type"}
                fullWidth
              />
            </Grid>{" "}
            <Grid xs={2} sm={4} md={4} px={1.5} py={2}>
              <TextField
                onChange={handleonChangeUserList}
                name="email"
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "400",
                    fontStyle: "normal",
                    fontSize: 14,
                    lineHeight: "14px",
                  },
                  "& .MuiFormHelperText-root": {
                    fontFamily: "Public Sans",
                    fontWeight: 400,
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    //   height: "40px",
                    borderColor: "#919EAB",
                    //   minWidth: "25vw",
                  },
                }}
                label={"Personal Email"}
                fullWidth
              />
            </Grid>{" "}
            <Grid xs={2} sm={4} md={4} px={1.5} py={2}>
              <TextField
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "400",
                    fontStyle: "normal",
                    fontSize: 14,
                    lineHeight: "14px",
                  },
                  "& .MuiFormHelperText-root": {
                    fontFamily: "Public Sans",
                    fontWeight: 400,
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    //   height: "40px",
                    borderColor: "#919EAB",
                    //   minWidth: "25vw",
                  },
                }}
                label={"Phone Number"}
                onChange={handleonChangeUserList}
                name="moblie"
                fullWidth
              />
            </Grid>{" "}
            <Grid xs={2} sm={4} md={4} px={1.5} py={2}>
              <TextField
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "400",
                    fontStyle: "normal",
                    fontSize: 14,
                    lineHeight: "14px",
                  },
                  "& .MuiFormHelperText-root": {
                    fontFamily: "Public Sans",
                    fontWeight: 400,
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    //   height: "40px",
                    borderColor: "#919EAB",
                    //   minWidth: "25vw",
                  },
                }}
                label={"PAN (Individual/Company)"}
                onChange={handleonChangeUserList}
                name="PAN"
                fullWidth
              />
            </Grid>{" "}
            <Grid xs={2} sm={4} md={4} px={1.5} py={2}>
              <TextField
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "400",
                    fontStyle: "normal",
                    fontSize: 14,
                    lineHeight: "14px",
                  },
                  "& .MuiFormHelperText-root": {
                    fontFamily: "Public Sans",
                    fontWeight: 400,
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    //   height: "40px",
                    borderColor: "#919EAB",
                    //   minWidth: "25vw",
                  },
                }}
                label={"Aadhar Card"}
                onChange={handleonChangeUserList}
                name="aadhar"
                fullWidth
              />
            </Grid>{" "}
            <Grid xs={2} sm={4} md={4} px={1.5} py={2}>
              <TextField
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "400",
                    fontStyle: "normal",
                    fontSize: 14,
                    lineHeight: "14px",
                  },
                  "& .MuiFormHelperText-root": {
                    fontFamily: "Public Sans",
                    fontWeight: 400,
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    //   height: "40px",
                    borderColor: "#919EAB",
                    //   minWidth: "25vw",
                  },
                }}
                label={"Address"}
                onChange={handleonChangeUserList}
                name="Address"
                fullWidth
              />
            </Grid>{" "}
            <Grid xs={2} sm={4} md={4} px={1.5} py={2}>
              <TextField
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "400",
                    fontStyle: "normal",
                    fontSize: 14,
                    lineHeight: "14px",
                  },
                  "& .MuiFormHelperText-root": {
                    fontFamily: "Public Sans",
                    fontWeight: 400,
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    //   height: "40px",
                    borderColor: "#919EAB",
                    //   minWidth: "25vw",
                  },
                }}
                label={"Pincode"}
                onChange={handleonChangeUserList}
                name="Pincode"
                fullWidth
              />
            </Grid>{" "}
            <Grid xs={2} sm={4} md={4} px={1.5} py={2}>
              <TextField
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "400",
                    fontStyle: "normal",
                    fontSize: 14,
                    lineHeight: "14px",
                  },
                  "& .MuiFormHelperText-root": {
                    fontFamily: "Public Sans",
                    fontWeight: 400,
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    //   height: "40px",
                    borderColor: "#919EAB",
                    //   minWidth: "25vw",
                  },
                }}
                label={"Business Location"}
                onChange={handleonChangeUserList}
                name="location"
                fullWidth
              />
            </Grid>{" "}
            <Grid xs={2} sm={4} md={4} px={1.5} py={2}>
              <Autocomplete
                options={array}
                getOptionLabel={(option: any) => option.label}
                // onChange={(event, value) => setselectCategorySearchObj(value)}
                fullWidth
                renderInput={(params: any) => (
                  <TextField
                    fullWidth
                    sx={{
                      "& .MuiFormLabel-root": {
                        color: "#919EAB",
                        fontFamily: "Public Sans",
                        fontWeight: "400",
                        fontStyle: "normal",
                        fontSize: 14,
                        lineHeight: "14px",
                      },
                      "& .MuiFormHelperText-root": {
                        fontFamily: "Public Sans",
                        fontWeight: 400,
                      },
                    }}
                    {...params}
                    // onChange={HandleOnChangeCategoryList}
                    InputLabelProps={{
                      sx: {
                        fontSize: "16px",
                        fontFamily: "Public Sans",
                        color: "#919EAB",
                        fontWeight: 400,
                      },
                    }}
                    InputProps={{
                      ...params.InputProps,
                      type: "search",
                      style: {
                        borderRadius: "8px",
                      },
                    }}
                    label={"Bank"}
                    onChange={handleonChangeUserList}
                    name="Bank"
                  />
                )}
              />
            </Grid>{" "}
            <Grid xs={2} sm={4} md={4} px={1.5} py={2}>
              <TextField
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "400",
                    fontStyle: "normal",
                    fontSize: 14,
                    lineHeight: "14px",
                  },
                  "& .MuiFormHelperText-root": {
                    fontFamily: "Public Sans",
                    fontWeight: 400,
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    //   height: "40px",
                    borderColor: "#919EAB",
                    //   minWidth: "25vw",
                  },
                }}
                label={"IFSC Code"}
                fullWidth
              />
            </Grid>{" "}
            <Grid xs={2} sm={4} md={4} px={1.5} py={2}>
              <TextField
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#919EAB",
                    fontFamily: "Public Sans",
                    fontWeight: "400",
                    fontStyle: "normal",
                    fontSize: 14,
                    lineHeight: "14px",
                  },
                  "& .MuiFormHelperText-root": {
                    fontFamily: "Public Sans",
                    fontWeight: 400,
                  },
                }}
                InputProps={{
                  style: {
                    borderRadius: "8px",
                    //   height: "40px",
                    borderColor: "#919EAB",
                    //   minWidth: "25vw",
                  },
                }}
                label={"Bank Account Number"}
                fullWidth
              />
            </Grid>
            {/* ))} */}
          </Grid>
          <Stack my={3}>
            <Button
              sx={{
                borderRadius: "8px",
                maxWidth: "320px",
                backgroundColor: "#303981",
                color: "white",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#303981",
                },
              }}
            >
              Create
            </Button>
          </Stack>
        </Box>
      </Box>
    </div>
  );
};

export default CreateUser;
