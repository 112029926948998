import { Box, Stack, Typography } from "@mui/material";
import OnBoardingLimits from "./OnBoardingLimits";
import TermsAndPolicies from "./TermsAndPolicies";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { onBoarding } from "../../redux/slices/admin";
import OnBoardingFees from "./OnBoardingFees";
import ApiService from "../../APIs/api";

const OnBoardingTools = () => {
  const dispatch = useDispatch();
  const [token, setToken] = useState("");
  const getUsersLimit = async (userToken: any) => {
    if (userToken !== "") {
      const setToken = await ApiService.setToken(userToken);
      const responseData: any = await ApiService.get("admin/getconfiguration");
      if (responseData.status === 200) {
        let data: any = responseData.data.result;
        dispatch(onBoarding(data));
      } else {
        console.log(responseData);
      }
    }
  };
  useEffect(() => {
    const userToken: any = localStorage.getItem("Token");
    setToken(userToken);
    getUsersLimit(userToken);
  }, []);
  return (
    <Box
      justifyContent={"center"}
      component={"div"}
      sx={
        {
          // backgroundColor: "#F7F9FB",
          // margin: 4,
        }
      }
    >
      <Stack>
        <Typography
          fontFamily={"Public Sans"}
          fontSize={22}
          sx={{ ml: 4, mt: 2 }}
          fontWeight={700}
          fontStyle={"normal"}
          color={"#000000"}
        >
          Onboarding Tools
        </Typography>
        <OnBoardingLimits props={token} />
        <TermsAndPolicies props={token} />
        <OnBoardingFees props={token} />
      </Stack>
    </Box>
  );
};

export default OnBoardingTools;
