import { Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header";
import { Router } from "./routes";
import { Toaster } from "react-hot-toast";
import LoginPage from "./pages/auth/LoginPage";
import NotAuthorised from "./components/NotAuthorised";
import TwoStepVerification from "./pages/auth/TwoStepAuthentication";
import { useProSidebar } from "react-pro-sidebar";
import ForgotPassword from "./pages/auth/ForgotPassword";
import { useSelector } from "react-redux";
import { AppBar, Box } from "@mui/material";
import Sidebars from "./components/Sidebar/Sidebars";

export default function App() {
  const collapsed = useProSidebar().collapsed;
  const token = useSelector((state: any) => state.user.userLogin.token);
  // const token = "";
  const ForgotPassowrdRouter = () => {
    return (
      <div>
        <Toaster />
        <Header />
        <ForgotPassword />
      </div>
    );
  };

  // This Router Facility is provide sidebars for application.
  const DashboardRouter = () => {
    if (token !== undefined && token !== "") {
      return (
        <Box sx={{ display: "flex" }}>
          <Sidebars />
          <Box component="main" sx={{ flexGrow: 1 }}>
            {/* <DrawerHeader /> */}
            <AppBar>
              <Toaster />
              <Header collapsed={collapsed} />
            </AppBar>

            <Box
              sx={{
                // backgroundColor: "#F7F9FB",
                mt: "50px",
                // margin: 4,
              }}
            >
              <Router />{" "}
            </Box>
          </Box>
        </Box>
      );
    } else {
      return (
        <div>
          <Toaster />
          <Header />
          <NotAuthorised />
        </div>
      );
    }
  };
  const LoginRouter = () => {
    return (
      <div>
        <Header />
        <LoginPage />
      </div>
    );
  };
  const TwoStepVerificationRouter = () => {
    return (
      <div>
        <Toaster />
        <Header />
        <TwoStepVerification />
      </div>
    );
  };

  return (
    <Routes>
      <Route path="/" element={LoginRouter()} />
      <Route path="/verification" element={TwoStepVerificationRouter()} />
      <Route path="/forgotpassword" element={ForgotPassowrdRouter()} />
      <Route path="/app/*" element={DashboardRouter()}></Route>
    </Routes>
  );
}
