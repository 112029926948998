import AdminLoginSvg from "../../assets/illustration_maintenance.svg";
import Grid from "@mui/material/Unstable_Grid2";
import SimpleLoader from "../../components/Loader";
import {
  Container,
  Typography,
  Box,
  TextField,
  Stack,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import ResendOtpIcon from "../../assets/ic_refresh.svg";
import { useSelector } from "react-redux";
import { login, loginUser } from "../../redux/slices/admin";
import { useDispatch } from "react-redux";
import ApiService from "../../APIs/api";

export default function TwoStepVerification() {
  const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userName = useSelector((state: any) =>
    state.user.userData ? state.user.userData.username : ""
  );
  const [username, setusername] = useState("");
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [OTPErrorMsg, setOTPErrorMsg] = useState(false);
  const [seconds, setSeconds] = useState(0);
  const [open, setopen] = useState(false);
  const [ResendOTPBtnDisabled, setResendOTPBtnDisabled] = useState(false);

  // Set UserName State
  const handleonChangeUserName = (e: any) => {
    setOTPErrorMsg(false);
    setusername(e.target.value);
  };

  // handleFormSubmit
  const handleFormSubmitLogin = async (e: any) => {
    setopen(true);
    setBtnDisabled(true);
    e.preventDefault();
    let data = new FormData(e.currentTarget);
    const responseData: any = await ApiService.post("/auth/verifylogin", {
      username: userName,
      otp: data.get("OTP"),
    });
    if (responseData.status === 200) {
      setopen(false);
      // I will get token from response and set into Redux Store
      dispatch(login(true));
      dispatch(loginUser(responseData.data.result));
      localStorage.setItem("Token", responseData.data.result.token);
      toast("SuccessFully Login", {
        style: {
          color: "green",
          fontFamily: "Public Sans",
          fontWeight: 700,
        },
      });
      navigate("/app/onboarding");
    } else {
      console.log(responseData);
      setopen(false);
      setOTPErrorMsg(true);
      setBtnDisabled(false);
    }
  };

  const resendOTPTimeSet = async () => {
    setResendOTPBtnDisabled(true);
    setSeconds(30);
    const responseData: any = await ApiService.post("/auth/resendverifycode", {
      username: userName,
    });
    if (responseData.status === 200) {
      // I will get token from response and set into Redux Store
    } else {
      // setSeconds(0);
      // setResendOTPBtnDisabled(false);
    }

    const FetchData = await axios
      .post(`${REACT_APP_BASE_URL}/auth/resendverifycode`, {
        username: userName,
      })
      .then((res) => {
        if (res.status === 200 && res.data.Active === true) {
          // I will get token from response and set into Redux Store
        }
      })
      .catch((err) => {});
  };
  const resendOTPOnClick = () => {
    setTimeout(() => {
      if (seconds === 0) {
        setResendOTPBtnDisabled(false);
      } else {
        setSeconds(seconds - 1);
      }
    }, 1000);
  };

  useEffect(() => {
    if (username !== "") {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  });
  useEffect(() => {
    resendOTPOnClick();
  }, [seconds]);

  return (
    <>
      <Box margin={2} sx={{ flexGrow: 1 }}>
        <Container>
          <Box
            margin={4}
            justifyContent={"center"}
            sx={{ display: "flex", flexDirection: "column" }}
            alignItems={"center"}
          >
            <Grid container spacing={{ xs: 5, md: 9, lg: 13 }}>
              <Grid>
                <Box
                  sx={{
                    boxShadow: 1,
                    borderRadius: 2,
                    px: 6,
                    pt: 4,
                    pb: 1,
                    marginTop: 15,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    maxWidth: "340px",
                  }}
                >
                  <Stack spacing={0.5} sx={{ mb: 3, position: "relative" }}>
                    <Typography
                      variant="h6"
                      component="div"
                      fontSize={18}
                      fontWeight={700}
                      fontFamily={"Public Sans"}
                      lineHeight={"30px"}
                      color={"#334A67"}
                    >
                      Two-step Verification
                    </Typography>
                    <Typography
                      component="div"
                      fontSize={12}
                      fontWeight={500}
                      fontFamily={"Public Sans"}
                      color={"#556486"}
                      letterSpacing={0.2}
                    >
                      {/* Enter code from your authenticator app for */}
                      Please enter your code for Two-step verification to
                      <br /> your account{" "}
                      <b style={{ fontSize: 13 }}>
                        {" "}
                        {userName ? userName : ""}
                      </b>
                    </Typography>

                    <Box
                      component="form"
                      onSubmit={handleFormSubmitLogin}
                      noValidate
                      sx={{ mt: 1 }}
                    >
                      <TextField
                        margin="normal"
                        error={OTPErrorMsg}
                        helperText={
                          OTPErrorMsg ? "Invalid OTP, Please try again !" : ""
                        }
                        sx={{
                          "& .MuiFormLabel-root": {
                            color: "#919EAB",
                            fontFamily: "Public Sans",
                            fontWeight: "500",
                            fontSize: 14,
                          },
                          "& .MuiFormHelperText-root": {
                            fontFamily: "Public Sans",
                            fontWeight: 500,
                          },
                        }}
                        fullWidth
                        name="OTP"
                        label="Enter Your OTP"
                        id="OTP"
                        onChange={handleonChangeUserName}
                        type="text"
                        size="small"
                        InputProps={{
                          style: {
                            borderRadius: "8px",
                            height: "40px",
                            borderColor: "#919EAB",
                          },
                        }}
                      />
                      <Stack
                        display={"flex"}
                        direction={"row"}
                        justifyContent={"center"}
                        spacing={3}
                        sx={{ mt: 2, mb: 2.5 }}
                      >
                        <Button
                          onClick={resendOTPTimeSet}
                          disabled={ResendOTPBtnDisabled}
                          style={{
                            color: ResendOTPBtnDisabled ? "grey" : "green",
                            textTransform: "none",
                            background: ResendOTPBtnDisabled
                              ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                              : "var(--transparent-primary-16, rgba(0, 171, 85, 0.16))",
                            borderRadius: "8px",
                            justifyContent: "center",
                            alignItems: "center",
                            minWidth: "155px",
                            fontWeight: "600",
                            fontFamily: "Public Sans",
                          }}
                          size="small"
                        >
                          <img
                            src={ResendOtpIcon}
                            style={{ marginRight: 2 }}
                            alt=""
                          />{" "}
                          Resend Code
                        </Button>
                        <Button
                          disabled={btnDisabled}
                          style={{
                            fontWeight: 600,
                            color: btnDisabled ? "grey" : "white",
                            textTransform: "none",
                            background: btnDisabled
                              ? "var(--action-light-disabled-background, rgba(145, 158, 171, 0.24))"
                              : "#303981",
                            borderRadius: "8px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "4px",
                            minWidth: "155px",
                            fontFamily: "Public Sans",
                          }}
                          size="small"
                          type="submit"
                        >
                          Verify
                        </Button>
                      </Stack>
                      {seconds > 0 ? (
                        <Typography
                          component="div"
                          fontSize={13}
                          marginTop={2}
                          fontWeight={500}
                          fontFamily={"Public Sans"}
                          color={"#556486"}
                        >
                          Please wait <b style={{ fontSize: 14 }}>{seconds}</b>{" "}
                          seconds for Resend OTP
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Stack>
                  <SimpleLoader props={open} />
                </Box>
              </Grid>
              <Grid>
                <img
                  src={AdminLoginSvg}
                  style={{
                    marginTop: "60px",
                    maxHeight: "350px",
                    maxWidth: "400px",
                    alignItems: "center",
                  }}
                  alt=""
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
    </>
  );
}
