import { Typography } from "@mui/material";

export const TypographyText = (props: any) => {
  return (
    <Typography
      fontFamily={"Public Sans"}
      fontSize={14}
      fontWeight={600}
      paddingTop={0.5}
      color={props.color}
    >
      {props.text}
    </Typography>
  );
};
