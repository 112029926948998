import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const ActivityBox = (props: any) => {
  return (
    <Box
      sx={{
        backgroundColor: props.bgColor,
        borderRadius: "10px",
        mx: 3,
      }}
      maxWidth={"516px"}
    >
      <Stack display={"flex"} direction={"row"} spacing={6} mx={4} py={1.5}>
        <Stack>
          <Typography
            fontFamily={"Public Sans"}
            fontWeight={400}
            fontSize={"12px"}
            lineHeight={"12px"}
            color={"background: rgba(0, 0, 0, 1)"}
          >
            Mac Address
          </Typography>
          <Typography
            fontFamily={" Public Sans"}
            fontSize={"16px"}
            fontStyle={"normal"}
            fontWeight={"400"}
            lineHeight={"24px"}
            mt={0.5}
          >
            M3:F4:2R:35:53:E6
          </Typography>
        </Stack>
        <Stack>
          <Typography
            fontFamily={"Public Sans"}
            fontWeight={400}
            fontSize={"12px"}
            lineHeight={"12px"}
            color={"background: rgba(0, 0, 0, 1)"}
          >
            IP Address
          </Typography>
          <Typography
            font-family={" Public Sans"}
            fontSize={"16px"}
            fontStyle={"normal"}
            fontWeight={"400"}
            lineHeight={"24px"}
            mt={0.5}
          >
            192.168.0.1
          </Typography>
        </Stack>
        <Stack>
          <Typography
            fontFamily={"Public Sans"}
            fontWeight={400}
            fontSize={"12px"}
            lineHeight={"12px"}
            color={"background: rgba(0, 0, 0, 1)"}
          >
            Status
          </Typography>
          <Typography
            font-family={" Public Sans"}
            fontSize={"16px"}
            fontStyle={"normal"}
            fontWeight={"400"}
            lineHeight={"24px"}
            mt={0.5}
          >
            {props.status}
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};

export default ActivityBox;
