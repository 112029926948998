export function firstCharacterUppercase(e: any) {
  return e[0].toUpperCase() + e.slice(1);
}
export function CharacterUppercase(e: any) {
  console.log(e, "returneeee");
  if (e !== undefined) {
    return e.toUpperCase();
  }
}
export function CharacterLowercase(e: any) {
  console.log(e, "returneeee");
  if (e !== undefined) {
    return e.toLowerCase();
  }
}
