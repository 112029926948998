import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

// redux ----
import { Provider as ReduxProvider } from "react-redux";
import { persistor, store } from "./redux/store";

// Routes ----
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
// import { ProSidebarProvider } from "react-pro-sidebar";
import { StyledEngineProvider } from "@mui/material/styles";
import { ProSidebarProvider } from "react-pro-sidebar";
import { SnackbarProvider } from "notistack";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ReduxProvider store={store}>
    <PersistGate loading={<div>loading...</div>} persistor={persistor}>
      <BrowserRouter>
        <ProSidebarProvider>
          <StyledEngineProvider injectFirst>
            <SnackbarProvider
              maxSnack={3}
              autoHideDuration={2500}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <App />
            </SnackbarProvider>
          </StyledEngineProvider>
        </ProSidebarProvider>
      </BrowserRouter>
    </PersistGate>
  </ReduxProvider>
);
